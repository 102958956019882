/** @format */

import { useEffect, useState } from "react";
import { Invoice } from "../models/invoices";
import Results from "../models/results";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useCellSelection from "../hooks/useCellSelection";
import { AttentionNeededTag, PaymentStatusTag } from "../components/statusTags";
import { formatDateFromDb } from "../utils/date/date_formatter";
import { formatMoney } from "../utils/price/formatMoney";
import { PaginationResults } from "../utils/pagination/paginationResults";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { useClient } from "../utils/client/clientContext";
import { Column } from "./models";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import ColumnSort from "../utils/sort/columnSort";

function InvoiceAttentionStatusTag({
    start_date,
    status,
}: {
    start_date: string;
    status: string;
}) {
    const startDate = new Date(start_date);
    const currentDate = new Date();

    const differenceInTime: number =
        currentDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    if (differenceInDays >= 14 && status === "open") {
        return (
            <div
                className={`h-7 w-fit flex items-center gap-2 px-4 text-xs border border-green-300 bg-green-50 shadow-sm rounded-md text-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
            >
                Ready to be closed
            </div>
        );
    } else if (status === "open") {
        return (
            <div
                className={`h-7 w-fit flex items-center gap-2 px-4 text-xs border border-gray-300 bg-gray-50 shadow-sm rounded-md text-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
            >
                Open for {Math.floor(differenceInDays)} days
            </div>
        );
    } else {
        return <></>;
    }
}

const tableHeaders: Column[] = [
    {
        key: "payment_status",
        label: "Payment status",
        className: "sm:min-w-32 min-w-32",
        isSortable: true,
        nonMovable: false,
    },
    {
        key: "full_name",
        label: "Client",
        className: "min-w-32",
        isSortable: true,
        nonMovable: false,
    },
    {
        key: "invoice_date",
        label: "Invoice date",
        className: "min-w-32",
        isSortable: true,
        nonMovable: false,
    },
    {
        key: "invoice_period",
        label: "Invoice period",
        className: "sm:min-w-32 min-w-28",
        isSortable: true,
        nonMovable: false,
    },
    {
        key: "total_saved",
        label: "Total saved",
        className: "min-w-32",
        isSortable: true,
        nonMovable: false,
        isSummable: true,
    },
    {
        key: "total",
        label: "Total",
        className: "min-w-32",
        isSortable: true,
        nonMovable: false,
        isSummable: true,
    },
    {
        key: "attention",
        label: "Attention",
        className: "min-w-32",
        isSortable: true,
        nonMovable: false,
    },
    {
        key: "view",
        label: "",
        className: "",
        isSortable: true,
        nonMovable: false,
    },
];

function InvoicesTable({
    invoices,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    setSelectedPastInvoice,
    setTotalSum,
}: {
    invoices: Invoice[];
    results: Results;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    setSelectedPastInvoice?: React.Dispatch<
        React.SetStateAction<Invoice | null>
    >;
    setTotalSum?: React.Dispatch<React.SetStateAction<string>>;
}) {
    const [searchParams] = useSearchParams();

    const { user_id } = useClient();

    const isAdmin = () => window.location.href.includes("admin");

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        switch (columnIndex) {
            case 3:
                return order.total_saved;
            case 4:
                return order.total;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [3, 4]);

    const hiddenHeaders: string[] = [];

    const displayedHeaders = tableHeaders.filter(
        (header) =>
            !hiddenHeaders.includes(header.key) &&
            (header.key !== "full_name" || (isAdmin() && !user_id)) &&
            (header.key !== "attention" || (isAdmin() && !user_id))
    );

    useEffect(() => {
        console.log(sumSelectedQuantities);
        const formattedTotal = sumSelectedQuantities
            .filter(({ sum }) => sum > 0)
            .map(({ columnIndex, sum }) =>
                displayedHeaders &&
                ["unit_cost", "total", "total_saved"].includes(
                    displayedHeaders[columnIndex].key
                )
                    ? formatMoney(sum)
                    : sum.toString()
            )
            .join(", ");

        setTotalSum && setTotalSum(formattedTotal);
    }, [sumSelectedQuantities]);

    const navigate = useNavigate();
    const handleRowClick = (invoice: Invoice) => {
        if (isAdmin()) {
            if (invoice.status === "open") {
                navigate(
                    `/admin/clients/${invoice.user_id}/invoices/${invoice.invoice_id}`
                );
            } else {
                setSelectedPastInvoice && setSelectedPastInvoice(invoice);
            }
        } else {
            navigate(`/billing/${invoice.invoice_id}`);
        }
    };

    return (
        <div>
            {invoices && invoices.length > 0 ? (
                <>
                    <div
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar`}
                    >
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map(
                                    (invoice: Invoice, rowIndex: number) => (
                                        <tr
                                            key={rowIndex}
                                            onClick={() =>
                                                handleRowClick(invoice)
                                            }
                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (
                                                        header: Column,
                                                        colIndex: number
                                                    ) => (
                                                        <>
                                                            {header.key ===
                                                            "payment_status" ? (
                                                                <td className="py-2.5">
                                                                    <PaymentStatusTag
                                                                        status={
                                                                            invoice.payment_status
                                                                        }
                                                                        value={
                                                                            !invoice.invoice_url
                                                                                ? "waiting for invoice url"
                                                                                : ""
                                                                        }
                                                                    />
                                                                </td>
                                                            ) : header.key ===
                                                              "invoice_date" ? (
                                                                <td className="py-2.5 text-sm">
                                                                    {invoice.invoice_date
                                                                        ? formatDateFromDb(
                                                                              String(
                                                                                  invoice[
                                                                                      header.key as keyof Invoice
                                                                                  ]
                                                                              )
                                                                          )
                                                                        : "None"}
                                                                </td>
                                                            ) : header.key ===
                                                              "invoice_period" ? (
                                                                <td className="">
                                                                    {formatDateFromDb(
                                                                        invoice.start_date
                                                                    )}
                                                                    {" - "}
                                                                    {invoice.end_date
                                                                        ? formatDateFromDb(
                                                                              invoice.end_date
                                                                          )
                                                                        : "Today"}
                                                                </td>
                                                            ) : header.key ===
                                                                  "total" &&
                                                              invoice.status ===
                                                                  "open" ? (
                                                                <td>
                                                                    Press to
                                                                    view
                                                                </td>
                                                            ) : header.key ===
                                                              "attention" ? (
                                                                <>
                                                                    {isAdmin() &&
                                                                        !user_id &&
                                                                        user_id !==
                                                                            "" && (
                                                                            <td>
                                                                                <InvoiceAttentionStatusTag
                                                                                    start_date={
                                                                                        invoice.start_date
                                                                                    }
                                                                                    status={
                                                                                        invoice.status
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        )}
                                                                </>
                                                            ) : header.key ===
                                                              "view" ? (
                                                                <td className="text-right">
                                                                    <a
                                                                        href={
                                                                            isAdmin()
                                                                                ? `/admin/clients/${invoice.user_id}/invoices/${invoice.invoice_id}`
                                                                                : `/billing/${invoice.invoice_id}`
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            e.stopPropagation()
                                                                        }
                                                                        className="text-accent hover:text-accenthighlight"
                                                                    >
                                                                        View
                                                                    </a>
                                                                </td>
                                                            ) : header.isSummable ? (
                                                                <td
                                                                    key={`${rowIndex}-${header.key}`}
                                                                    onMouseDown={() =>
                                                                        handleMouseDown(
                                                                            rowIndex,
                                                                            colIndex,
                                                                            invoices
                                                                        )
                                                                    }
                                                                    onMouseEnter={() =>
                                                                        handleMouseEnter(
                                                                            rowIndex,
                                                                            colIndex,
                                                                            invoices
                                                                        )
                                                                    }
                                                                    onClick={() =>
                                                                        handleSingleClick(
                                                                            rowIndex,
                                                                            colIndex,
                                                                            invoices
                                                                        )
                                                                    }
                                                                    className={`py-2.5 ${
                                                                        selectedCells.some(
                                                                            (
                                                                                cell
                                                                            ) =>
                                                                                cell.rowIndex ===
                                                                                    rowIndex &&
                                                                                cell.columnIndex ===
                                                                                    colIndex
                                                                        )
                                                                            ? "bg-blue-200 select-none"
                                                                            : "select-none"
                                                                    }`}
                                                                >
                                                                    {[
                                                                        "total_saved",
                                                                        "total",
                                                                    ].includes(
                                                                        String(
                                                                            header.key
                                                                        )
                                                                    ) ? (
                                                                        <>
                                                                            {formatMoney(
                                                                                Number(
                                                                                    invoice[
                                                                                        header.key as keyof Invoice
                                                                                    ]
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {String(
                                                                                invoice[
                                                                                    header.key as keyof Invoice
                                                                                ]
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`${rowIndex}-${header.key}`}
                                                                    className="py-2.5 text-sm"
                                                                >
                                                                    {
                                                                        invoice[
                                                                            header.key as keyof Invoice
                                                                        ]
                                                                    }
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <PaginationResults results={results} />
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/billing?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/billing?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center text-sm text-gray-500">
                    No past invoices.
                </div>
            )}
        </div>
    );
}

export default InvoicesTable;
