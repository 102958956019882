/** @format */

import { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { PrinterIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { InputField } from "../../../../../../components/inputField";
import SingleCalendar from "../../../../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import { createShipment } from "../../../../api/shipmentsApi";
import { formatDateFromObject } from "../../../../../../utils/date/date_formatter";
import LoadingWheel from "../../../../../../components/loadingWheel";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import { Order, ShipmentQuantities } from "../../../../../../models/order";
import React, { ChangeEvent, KeyboardEvent } from "react";
import { ScannedBarcode } from "../../../../../../models/scannedBarcode";
import { v4 as uuidv4 } from "uuid";
import { fetchBoxes } from "../../../../api/settingsApi";
import { Box } from "../../../../../../models/adminDashboard";
import { ShipmentService } from "../../../../../../models/shipments";
import { useClient } from "../../../../../../utils/client/clientContext";
import OrdersTable, {
    getTableHeaders,
} from "../../../../../../tables/ordersTable";
import { useSort } from "../../../../../../hooks/useSort";
import { Column } from "../../../../../../tables/models";
import ColumnOrderDropdown from "../../../../../../tables/columnDragPopup";
import { orderTypes } from "../../../../../../models/data";
import {
    ActionButton,
    DeleteButton,
    SecondaryButton,
} from "../../../../../../components/buttons";
import { XCircleIcon } from "@heroicons/react/20/solid";
import ServicesPopup from "../../../../outbound/components/servicesPopup";
import BoxesPopup from "../../../../outbound/components/boxesPopup";
import ConfirmLoggedOutModal from "./confirmLoggedOutModal";
import useKpiTracker from "../../../../../../hooks/useKpiTracker";

const downloadInventorylabsList = (selectedInventoryOrders: Order[]) => {
    const rows = selectedInventoryOrders.map((order) => {
        const formattedDate = new Date(order.date).toLocaleDateString("en-US");

        return [
            formattedDate,
            order.retailer,
            order.asin,
            order.amazon_sku,
            order.unit_cost && !isNaN(order.unit_cost)
                ? order.unit_cost / 100
                : "",
            order.list_price && !isNaN(order.list_price)
                ? order.list_price / 100
                : order.unit_cost && !isNaN(order.unit_cost)
                ? (order.unit_cost / 100) * 3
                : "",
            order.quantity_remaining,
        ];
    });
    const headers = [
        "Purchase Date",
        "Supplier",
        "ASIN",
        "MSKU",
        "Cost/Unit",
        "List Price",
        "Quantity",
    ];

    const csvRows = [headers, ...rows];
    const csvContent = csvRows.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "inventorylabs_list.csv";
    link.click();
};

export default function CreateOutboundShipmentModal({
    selectedInventoryOrders,
    setSelectedInventoryOrders,
    openCreateOutbountShipment,
    setOpenCreateOutbountShipment,
    handleFullRefetch,
}: {
    selectedInventoryOrders: Order[];
    setSelectedInventoryOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    openCreateOutbountShipment: boolean;
    setOpenCreateOutbountShipment: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    handleFullRefetch: () => void;
}) {
    const { client, user_id } = useClient();
    const { startKpi, endKpi, actionId } = useKpiTracker();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);

    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [shipmentName, setShipmentName] = useState("");
    const [notes, setNotes] = useState("");

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

    const handleCreateShipment = async () => {
        if (!validateQuantities()) {
            return; // Validation failed, prevent submission
        }
        setLoading(true);

        const inventory_orders = selectedInventoryOrders.map((order) => ({
            orderId: order.order_id,
            quantityShipped: shipmentQuantities[order.order_id],
        }));

        const data = await createShipment(
            user_id,
            shipmentName,
            formatDateFromObject(selectedDate),
            notes,
            selectedBoxes,
            inventory_orders,
            scannedBarcodes,
            selectedServices
        );
        if (data.status === "success") {
            endKpi(
                data.data.shipment_id,
                "shipments",
                inventory_orders.reduce(
                    (sum, order) => sum + (Number(order.quantityShipped) || 0),
                    0
                )
            );
            handleFullRefetch();
            setOpenConfirmLoggedOutModal(true);

            setSelectedInventoryOrders([]);
            setScannedUpcs([]);
            setVerifiedOrderIds([]);
            setCurrentUPCInput("");
            setErrorMatchingUpcToOrder(false);
            setShipmentName("");
            setSelectedDate(dayjs());
            setNotes("");

            showNotification("Created outbound shipment", "", "success");
        } else {
            showNotification(
                "Failed creating outbound shipment",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const validateQuantities = (): boolean => {
        let isValid = true;

        selectedInventoryOrders.forEach((order) => {
            const quantity = shipmentQuantities[order.order_id];

            if (!quantity || parseInt(quantity) <= 0) {
                showNotification(
                    "Fill out all quantity shipped fields",
                    "",
                    "error"
                );
                isValid = false;
            }
        });

        return isValid;
    };

    const [scannedBarcodes, setScannedBarcodes] = useState<ScannedBarcode[]>(
        []
    );

    const [currentInput, setCurrentInput] = useState<string>("");

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentInput.trim()) {
            e.preventDefault();

            const newScannedBarcode: ScannedBarcode = {
                id: uuidv4(), // Generate a unique ID
                barcode: String(currentInput.trim()),
                dateScanned: new Date().toLocaleString(),
                isLoading: false, // Set loading to true initially
            };
            setScannedBarcodes((prev) => [...prev, newScannedBarcode]);

            setCurrentInput("");
        }
    };

    const removeBarcode = (id: string) => {
        setScannedBarcodes((prev) => prev.filter((item) => item.id !== id));
    };

    useEffect(() => {
        if (!openCreateOutbountShipment) {
            setScannedBarcodes([]);
            setShipmentQuantities({});
            setSelectedServices([]);
            setScannedUpcs([]);
            setVerifiedOrderIds([]);
            setCurrentUPCInput("");
            setErrorMatchingUpcToOrder(false);
        } else {
            startKpi("fba_shipment_time");
        }
    }, [openCreateOutbountShipment]);

    const [boxesLoading, setBoxesLoading] = useState(false);
    const [boxes, setBoxes] = useState<Box[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            setBoxesLoading(true);
            const data = await fetchBoxes();
            if (data.status === "success") {
                setBoxes(data.data.boxes);
            } else {
                showNotification(
                    "Failed to fetch boxes",
                    data.message,
                    "error"
                );
            }
            setBoxesLoading(false);
        };
        fetchData();
    }, []);

    const [selectedBoxes, setSelectedBoxes] = useState<Box[]>([]);

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [selectedServices, setSelectedServices] = useState<ShipmentService[]>(
        []
    );

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inventory")
    );
    const hiddenHeaders: string[] = [
        "select",
        "input_shelf_location",
        "quick_actions",
    ];

    const [scannedUPCs, setScannedUpcs] = useState<string[]>([]);
    const [verifiedOrderIds, setVerifiedOrderIds] = useState<string[]>([]);
    const [currentUPCInput, setCurrentUPCInput] = useState<string>("");
    const [errorMatchingUpcToOrder, setErrorMatchingUpcToOrder] =
        useState(false);

    const handleUpcInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentUPCInput(e.target.value);
    };

    const confirmUpcMismatch = () => {
        setScannedUpcs((prevUPCs) => [...prevUPCs, currentUPCInput]);
        setCurrentUPCInput("");
        setErrorMatchingUpcToOrder(false);
    };

    const handleUpcKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentUPCInput.trim()) {
            e.preventDefault();

            const scannedUPC = currentUPCInput.trim();

            const matchingOrders = selectedInventoryOrders.filter(
                (order) =>
                    order.upc === scannedUPC &&
                    !verifiedOrderIds.includes(order.order_id)
            );

            if (matchingOrders.length > 0) {
                setVerifiedOrderIds((prevIds) => [
                    ...prevIds,
                    ...matchingOrders.map((order) => order.order_id),
                ]);

                setScannedUpcs((prevUPCs) => [
                    ...prevUPCs,
                    ...Array(matchingOrders.length).fill(scannedUPC),
                ]);

                setCurrentUPCInput("");
                setErrorMatchingUpcToOrder(false);
            } else {
                setErrorMatchingUpcToOrder(true);
            }
        }
    };

    const handleRemoveUpc = (scannedUPC: string) => {
        setScannedUpcs((prevUPCs) => {
            const indexToRemove = prevUPCs.indexOf(scannedUPC);
            if (indexToRemove === -1) return prevUPCs; // UPC not found

            // Create a new array without the removed UPC
            const updatedUPCs = [...prevUPCs];
            updatedUPCs.splice(indexToRemove, 1);

            // Remove one matching verifiedOrderId if it exists
            let removedOrderId = null;
            setVerifiedOrderIds((prevIds) => {
                for (const order of selectedInventoryOrders) {
                    if (
                        order.upc === scannedUPC &&
                        prevIds.includes(order.order_id)
                    ) {
                        removedOrderId = order.order_id;
                        return prevIds.filter((id) => id !== order.order_id);
                    }
                }
                return prevIds; // No match found
            });

            return updatedUPCs;
        });
    };

    const canCreateShipment =
        scannedUPCs.length === selectedInventoryOrders.length &&
        !errorMatchingUpcToOrder;

    const [openConfirmLoggedOutModal, setOpenConfirmLoggedOutModal] =
        useState(false);

    return (
        <>
            <Dialog
                open={openCreateOutbountShipment}
                onClose={() => setOpenCreateOutbountShipment(false)}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-20 w-screen">
                    <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-[95%] sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="absolute right-0 top-0 pr-4 pt-4">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setOpenCreateOutbountShipment(false)
                                    }
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>

                            <DialogTitle
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900 pb-5"
                            >
                                Create outbound shipment
                                <div className="text-sm text-gray-600 font-normal flex flex-row items-center divide-x space-x-2 pt-1">
                                    <span>
                                        {client?.first_name +
                                            " " +
                                            client?.last_name}
                                    </span>
                                    <span className="pl-2">{user_id}</span>
                                </div>
                            </DialogTitle>
                            <div className="w-full h-px bg-gray-200"></div>

                            <div className="flex-1 mb-4 overflow-y-auto">
                                <div className="pt-4"></div>

                                <div className="text-sm pt-2 flex flex-row items-center justify-between pb-2">
                                    <span>
                                        {selectedInventoryOrders.length} order
                                        selected
                                    </span>
                                    <div className="flex flex-row items-center space-x-4">
                                        {totalSum !== "" && (
                                            <span className="text-gray-700 text-sm">
                                                Sum: {totalSum}
                                            </span>
                                        )}
                                        <ColumnOrderDropdown
                                            columnOrder={unitsColumnOrder}
                                            setColumnOrder={setUnitsColumnOrder}
                                            localStorageKey={"unitsColumnOrder"}
                                            selectedOrderType={
                                                selectedOrderType
                                            }
                                            initialColumnOrder={getTableHeaders(
                                                "inventory"
                                            )}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`${
                                        scannedUPCs.length ===
                                            selectedInventoryOrders.length &&
                                        !errorMatchingUpcToOrder
                                            ? "border-green-200 bg-green-100"
                                            : !errorMatchingUpcToOrder
                                            ? "border-gray-200 bg-gray-100"
                                            : "border-red-200 bg-red-100"
                                    } border py-3 flex flex-row items-center justify-between px-2 rounded-md`}
                                >
                                    <div>
                                        {scannedUPCs.length ===
                                            selectedInventoryOrders.length &&
                                        !errorMatchingUpcToOrder ? (
                                            <h1 className="text-green-700 font-semibold text-sm">
                                                Shipment Ready to Be Shipped!
                                            </h1>
                                        ) : !errorMatchingUpcToOrder ? (
                                            <input
                                                type="text"
                                                value={currentUPCInput}
                                                onChange={handleUpcInputChange}
                                                onKeyDown={handleUpcKeyDown}
                                                className="block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                placeholder="Press here and scan the UPC's"
                                            />
                                        ) : (
                                            <div className="flex flex-row items-center space-x-2">
                                                <h1 className="text-red-600 font-medium text-sm">
                                                    UPC doesn't match (
                                                    {currentUPCInput}
                                                    ):
                                                </h1>
                                                <ActionButton
                                                    label="Confirm UPC Anyways"
                                                    handleClick={() =>
                                                        confirmUpcMismatch()
                                                    }
                                                />
                                                <DeleteButton
                                                    label="Delete UPC"
                                                    handleClick={() => {
                                                        setCurrentUPCInput("");
                                                        setErrorMatchingUpcToOrder(
                                                            false
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {scannedUPCs.length > 0 && (
                                            <div className="flex flex-wrap flex-row items-center text-sm text-gray-700 divide-x-2 gap-x-2 pt-2">
                                                <span>Scanned UPCs:</span>
                                                {scannedUPCs.map((item) => (
                                                    <div className="pl-2 flex flex-row items-center space-x-2">
                                                        <p className="">
                                                            {item}
                                                        </p>
                                                        <XCircleIcon
                                                            onClick={() =>
                                                                handleRemoveUpc(
                                                                    item
                                                                )
                                                            }
                                                            className="text-red-500 h-4 w-4 hover:text-red-400 cursor-pointer"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className="flex flex-wrap flex-row items-center text-sm text-gray-700 divide-x-2 gap-x-2 pt-2">
                                            <span>Unscanned UPCs:</span>
                                            {selectedInventoryOrders
                                                .map((o) => o.upc)
                                                .filter(
                                                    (upc) =>
                                                        !scannedUPCs.includes(
                                                            upc || ""
                                                        )
                                                )
                                                .map((item) => (
                                                    <p className="pl-2">
                                                        {item}
                                                    </p>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center text-accent font-semibold text-sm">
                                        {scannedUPCs.length}/
                                        {selectedInventoryOrders.length} UPCs
                                        Scanned
                                    </div>
                                </div>

                                <OrdersTable
                                    orderStatus="inventory"
                                    orders={selectedInventoryOrders}
                                    results={null}
                                    selectedOrders={[]}
                                    setSelectedOrders={
                                        setSelectedInventoryOrders
                                    }
                                    sortLoading={sortLoading}
                                    sortConfig={sortConfig}
                                    toggleSort={toggleSort}
                                    handleRefetch={() => {}}
                                    client_type="admin"
                                    setTotalSum={setTotalSum}
                                    columnOrder={unitsColumnOrder}
                                    hiddenHeaders={hiddenHeaders}
                                    shipmentQuantities={shipmentQuantities}
                                    handleQuantityChange={handleQuantityChange}
                                    shipmentInputName="Shipping quantity"
                                    verifiedOrderIds={verifiedOrderIds}
                                />

                                <div className="flex flex-col space-y-4">
                                    <div className="flex flex-row w-full space-x-4">
                                        <div className="pt-0.5 w-full">
                                            <InputField
                                                label="Shipment name"
                                                value={shipmentName}
                                                onChange={(e) =>
                                                    setShipmentName(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="w-full">
                                            <SingleCalendar
                                                selectedDate={selectedDate}
                                                setSelectedDate={
                                                    setSelectedDate
                                                }
                                            />
                                        </div>
                                        <div className="pt-0.5 w-full">
                                            <InputField
                                                label="Notes"
                                                value={notes}
                                                onChange={(e) =>
                                                    setNotes(e.target.value)
                                                }
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-full space-x-10">
                                        <BoxesPopup
                                            boxes={boxes}
                                            selectedBoxes={selectedBoxes}
                                            setSelectedBoxes={setSelectedBoxes}
                                        />
                                        <ServicesPopup
                                            selectedServices={selectedServices}
                                            setSelectedServices={
                                                setSelectedServices
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="space-y-3">
                                    <span className="font-semibold text-sm">
                                        Trackings
                                    </span>
                                    <input
                                        type="text"
                                        id="tracking"
                                        value={currentInput}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        placeholder="Press here and start scanning."
                                    />

                                    <div className="grid grid-cols-2 gap-x-5 gap-y-3">
                                        {scannedBarcodes.map(
                                            (barcode, index) => (
                                                <div className="flex flex-row w-full justify-between items-center">
                                                    <div className="flex flex-col">
                                                        <span className="text-sm">
                                                            {barcode.barcode}
                                                        </span>
                                                        <span className="text-sm text-gray-600">
                                                            {
                                                                barcode.dateScanned
                                                            }
                                                        </span>
                                                        {barcode.error ? (
                                                            <span className="text-xs text-red-500">
                                                                {barcode.error}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    {barcode.isLoading ? (
                                                        <div className="w-5 h-5">
                                                            <LoadingWheel />{" "}
                                                        </div>
                                                    ) : (
                                                        <TrashIcon
                                                            onClick={() =>
                                                                removeBarcode(
                                                                    barcode.id
                                                                )
                                                            }
                                                            className="w-5 h-5 text-red-500"
                                                        />
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Fixed buttons at the bottom */}
                            <div className="pt-4 sm:pt-0 sm:flex sm:justify-between sm:flex-row-reverse sm:gap-x-3">
                                <div className="flex flex-row-reverse items-center gap-x-4">
                                    <ActionButton
                                        label="Create shipment"
                                        handleClick={() =>
                                            handleCreateShipment()
                                        }
                                        loading={loading}
                                        viewDisabled={!canCreateShipment}
                                        height={8}
                                    />
                                    <SecondaryButton
                                        label="Inventorylabs List"
                                        handleClick={() =>
                                            downloadInventorylabsList(
                                                selectedInventoryOrders
                                            )
                                        }
                                        height={8}
                                        iconDisplayed={PrinterIcon}
                                    />
                                </div>
                                <SecondaryButton
                                    label="Cancel"
                                    handleClick={() =>
                                        setOpenCreateOutbountShipment(false)
                                    }
                                    height={8}
                                />
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <ConfirmLoggedOutModal
                openConfirmLoggedOutModal={openConfirmLoggedOutModal}
                setOpenConfirmLoggedOutModal={setOpenConfirmLoggedOutModal}
                setOpenCreateOutbountShipment={setOpenCreateOutbountShipment}
            />
        </>
    );
}
