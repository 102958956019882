/** @format */

import { useState, useEffect } from "react";
import { apiRequest } from "../utils/api/apiRequest";

interface UseKpiTrackerReturn {
    actionId: string | null;
    startKpi: (actionType: string) => Promise<void>;
    endKpi: (
        row_id: string,
        table_name: string,
        total_units: number
    ) => Promise<void>;
}

const useKpiTracker = (): UseKpiTrackerReturn => {
    const [actionId, setActionId] = useState<string | null>(null);

    useEffect(() => {
        const storedId = localStorage.getItem("actionId");
        if (storedId) {
            setActionId(storedId);
        }
    }, []);

    const startKpi = async (action_type: string): Promise<void> => {
        const data = await apiRequest("/admin/kpi/start", "POST", {
            action_type,
        });
        if (data.status === "success") {
            setActionId(data.data.id);
            localStorage.setItem("actionId", data.data.id);
        }
    };

    const endKpi = async (
        row_id: string,
        table_name: string,
        total_units: number
    ): Promise<void> => {
        if (!actionId) return;

        await apiRequest("/admin/kpi/end", "POST", {
            actionId,
            row_id,
            table_name,
            total_units,
        });
        setActionId(null);
        localStorage.removeItem("actionId");
    };

    return { startKpi, endKpi, actionId };
};

export default useKpiTracker;
