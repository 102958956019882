import Cookies from "js-cookie";
import { SortConfig } from "../../../../hooks/useSort";
import { apiRequest } from "../../../../utils/api/apiRequest";
import { buildUrl } from "../../../../utils/url/buildUrl";



export const fetchInvoices = async (
    sortConfig: SortConfig
) => {
    const params = {
        offset: "0",
        limit: "200",
        sort_key: sortConfig ? sortConfig.key : "created_at",
        sort_order: sortConfig ? sortConfig.order : "desc"
    };

    const url = buildUrl('/invoices', params);

    return apiRequest(url, "GET")
}



export const fetchInvoiceData = async (
    invoice_id: string | undefined,
    user_id?: string | undefined
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/v1/invoices/${invoice_id}${user_id ? `?user_id=${encodeURIComponent(user_id || "")}` : ""}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt") 
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const createStripeBillingPortal = async () => {
    return apiRequest("/stripe/create-billing-portal", "POST")
}

export const fetchPaymentDetails = async () => {
    return apiRequest("/stripe/payment-details", "GET")
}

export const fetchStripeInvoice = async (stripe_invoice_id: string) => {
    return apiRequest(`/stripe/invoice/${stripe_invoice_id}`, "GET")
}