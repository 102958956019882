/** @format */

import React, { useState, useEffect, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";

const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

interface SingleCalendarProps {
    selectedDate: Dayjs;
    setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    label?: string | null;
    disabled?: boolean;
    allowFutureDates?: boolean;
}

const SingleCalendar: React.FC<SingleCalendarProps> = ({
    selectedDate,
    setSelectedDate,
    label = "Date",
    disabled = false,
    allowFutureDates = false,
}) => {
    const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);

    const calendarRef = useRef<HTMLDivElement>(null);
    const toggleButtonRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                calendarRef.current &&
                toggleButtonRef.current &&
                !calendarRef.current.contains(event.target as Node) &&
                !toggleButtonRef.current.contains(event.target as Node)
            ) {
                setIsCalendarVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const startOfMonth = currentDate.startOf("month");
    const endOfMonth = currentDate.endOf("month");

    // Helper function to get start of week on Monday
    const getStartOfWeekMonday = (date: Dayjs) => {
        const weekday = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const diff = (weekday + 6) % 7; // Calculate difference to Monday
        return date.subtract(diff, "day").startOf("day");
    };

    // Adjust the start and end of the calendar based on the week starting on Monday
    const startDate = getStartOfWeekMonday(startOfMonth);
    const endDate = getStartOfWeekMonday(endOfMonth).add(6, "days");

    const handleDateClick = (date: Dayjs) => {
        if (!allowFutureDates && date.isAfter(today, "day")) return; // Block future dates if allowFutureDates is false
        setSelectedDate(date);
    };

    const generateCalendarDays = () => {
        const days: Dayjs[] = [];
        let day = startDate;

        while (day.isBefore(endDate, "day") || day.isSame(endDate, "day")) {
            days.push(day);
            day = day.add(1, "day");
        }

        return days;
    };

    const handlePrevMonth = () => {
        setCurrentDate(currentDate.subtract(1, "month"));
    };

    const handleNextMonth = () => {
        const nextMonth = currentDate.add(1, "month");
        if (!nextMonth.isAfter(today, "month")) {
            setCurrentDate(nextMonth);
        }
    };

    const handleToggleCalendar = () => {
        if (!isCalendarVisible && selectedDate) {
            setCurrentDate(selectedDate);
        }
        setIsCalendarVisible(!isCalendarVisible);
    };

    const days = generateCalendarDays();
    const today = dayjs();
    const yesterday = today.subtract(1, "day");

    const isNextMonthDisabled =
        !allowFutureDates &&
        currentDate.add(1, "month").isAfter(today, "month");

    const getDisplayText = () => {
        if (selectedDate?.isSame(today, "day")) {
            return "Today";
        } else if (selectedDate?.isSame(yesterday, "day")) {
            return "Yesterday";
        } else if (selectedDate) {
            return selectedDate.format("MMMM D, YYYY");
        }
        return "Select a date";
    };

    // return (
    //     <div className="relative">
    //         <div className="text-left w-full">
    //             {label && <span className="font-medium text-sm">{label}</span>}
    //             <span
    //                 ref={toggleButtonRef}
    //                 onClick={() => !disabled && handleToggleCalendar()}
    //                 className={`${
    //                     disabled
    //                         ? "bg-gray-100 text-gray-500"
    //                         : "cursor-pointer"
    //                 }  block pl-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6`}
    //             >
    //                 {getDisplayText()}
    //             </span>
    //         </div>
    //         <div
    //             ref={calendarRef}
    //             className={`absolute left-0 top-full mt-2 max-w-sm mx-auto bg-white rounded-xl shadow-lg transition-all duration-100 ease-in-out w-64 z-30 ${
    //                 isCalendarVisible
    //                     ? "scale-100 opacity-100"
    //                     : "scale-95 opacity-0 pointer-events-none"
    //             }`}
    //             style={{ transformOrigin: "top left" }}
    //         >
    //             <div className="p-4">
    //                 <div className="flex items-center justify-between mb-8">
    //                     <button
    //                         onClick={handlePrevMonth}
    //                         className="text-gray-600 hover:text-gray-800"
    //                     >
    //                         <GoChevronLeft className="h-5 w-5" />
    //                     </button>
    //                     <span className="text-sm font-semibold">
    //                         {currentDate.format("MMMM YYYY")}
    //                     </span>
    //                     <button
    //                         onClick={handleNextMonth}
    //                         className={`text-gray-600 hover:text-gray-800 ${
    //                             isNextMonthDisabled
    //                                 ? "cursor-not-allowed opacity-50"
    //                                 : ""
    //                         }`}
    //                         disabled={isNextMonthDisabled}
    //                     >
    //                         <GoChevronRight className="h-5 w-5" />
    //                     </button>
    //                 </div>
    //                 <div className="grid grid-cols-7 text-center mb-3">
    //                     {daysOfWeek.map((day, index) => (
    //                         <div key={index} className="text-xs text-gray-500">
    //                             {day}
    //                         </div>
    //                     ))}
    //                 </div>
    //                 <div className="border border-gray-200 rounded-xl overflow-hidden">
    //                     <div className="grid grid-cols-7 gap-px bg-gray-200 cursor-pointer">
    //                         {days.map((day) => {
    //                             const isToday = day.isSame(today, "day");
    //                             const isSelected =
    //                                 selectedDate &&
    //                                 day.isSame(selectedDate, "day");
    //                             const isCurrentMonth =
    //                                 day.month() === currentDate.month();
    //                             const isFuture = day.isAfter(today, "day");

    //                             let dayBackgroundClass =
    //                                 "bg-white hover:bg-gray-100 hover:cursor-pointer";
    //                             let dayTextClass = "text-gray-800";

    //                             if (!isCurrentMonth) {
    //                                 dayBackgroundClass =
    //                                     "bg-gray-50 hover:bg-gray-100 hover:cursor-pointer";
    //                                 dayTextClass = "text-gray-400";
    //                             }
    //                             if (isFuture) {
    //                                 dayBackgroundClass =
    //                                     "bg-gray-50 cursor-not-allowed";
    //                                 dayTextClass = "text-gray-400";
    //                             }

    //                             if (isSelected) {
    //                                 dayBackgroundClass =
    //                                     "bg-accent cursor-pointer";
    //                                 dayTextClass = "text-white";
    //                             } else if (isToday) {
    //                                 dayTextClass = "text-accent";
    //                             }

    //                             return (
    //                                 <div
    //                                     key={day.format("YYYY-MM-DD")}
    //                                     onClick={() =>
    //                                         !isFuture && handleDateClick(day)
    //                                     }
    //                                     className={`relative text-xs flex items-center justify-center h-8 ${dayBackgroundClass}`}
    //                                 >
    //                                     <span
    //                                         className={`relative ${dayTextClass}`}
    //                                     >
    //                                         {day.date()}
    //                                     </span>
    //                                 </div>
    //                             );
    //                         })}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
    return (
        <div className="relative">
            <div className="text-left w-full">
                {label && <span className="font-medium text-sm">{label}</span>}
                <span
                    ref={toggleButtonRef}
                    onClick={() =>
                        !disabled && setIsCalendarVisible(!isCalendarVisible)
                    }
                    className={`${
                        disabled
                            ? "bg-gray-100 text-gray-500"
                            : "cursor-pointer"
                    } block pl-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6`}
                >
                    {selectedDate
                        ? selectedDate.isSame(today, "day")
                            ? "Today"
                            : selectedDate.isSame(yesterday, "day")
                            ? "Yesterday"
                            : selectedDate.format("MMMM D, YYYY")
                        : "Select a date"}
                </span>
            </div>
            {isCalendarVisible && (
                <div
                    ref={calendarRef}
                    className="absolute left-0 top-full mt-2 max-w-sm mx-auto bg-white rounded-xl shadow-lg transition-all duration-100 ease-in-out w-64 z-30"
                    style={{ transformOrigin: "top left" }}
                >
                    <div className="p-4">
                        <div className="flex items-center justify-between mb-8">
                            <button
                                onClick={() =>
                                    setCurrentDate(
                                        currentDate.subtract(1, "month")
                                    )
                                }
                                className="text-gray-600 hover:text-gray-800"
                            >
                                <GoChevronLeft className="h-5 w-5" />
                            </button>
                            <span className="text-sm font-semibold">
                                {currentDate.format("MMMM YYYY")}
                            </span>
                            <button
                                onClick={() =>
                                    !isNextMonthDisabled &&
                                    setCurrentDate(currentDate.add(1, "month"))
                                }
                                className={`text-gray-600 hover:text-gray-800 ${
                                    isNextMonthDisabled
                                        ? "cursor-not-allowed opacity-50"
                                        : ""
                                }`}
                                disabled={isNextMonthDisabled}
                            >
                                <GoChevronRight className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="grid grid-cols-7 text-center mb-3">
                            {daysOfWeek.map((day, index) => (
                                <div
                                    key={index}
                                    className="text-xs text-gray-500"
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                        <div className="border border-gray-200 rounded-xl overflow-hidden">
                            <div className="grid grid-cols-7 gap-px bg-gray-200 cursor-pointer">
                                {generateCalendarDays().map((day) => {
                                    const isToday = day.isSame(today, "day");
                                    const isSelected =
                                        selectedDate &&
                                        day.isSame(selectedDate, "day");
                                    const isCurrentMonth =
                                        day.month() === currentDate.month();
                                    const isFuture = day.isAfter(today, "day");

                                    let dayBackgroundClass =
                                        "bg-white hover:bg-gray-100";
                                    let dayTextClass = "text-gray-800";

                                    if (!isCurrentMonth) {
                                        dayBackgroundClass =
                                            "bg-gray-50 hover:bg-gray-100";
                                        dayTextClass = "text-gray-400";
                                    }
                                    if (!allowFutureDates && isFuture) {
                                        dayBackgroundClass =
                                            "bg-gray-50 cursor-not-allowed";
                                        dayTextClass = "text-gray-400";
                                    }
                                    if (isSelected) {
                                        dayBackgroundClass =
                                            "bg-accent text-white";
                                        dayTextClass = "text-white";
                                    } else if (isToday) {
                                        dayTextClass = "text-accent";
                                    }

                                    return (
                                        <div
                                            key={day.format("YYYY-MM-DD")}
                                            onClick={() =>
                                                (allowFutureDates ||
                                                    !isFuture) &&
                                                handleDateClick(day)
                                            }
                                            className={`relative text-xs flex items-center justify-center h-8 ${dayBackgroundClass}`}
                                        >
                                            <span className={dayTextClass}>
                                                {day.date()}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SingleCalendar;
