/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../../../components/admin/sideBar";
import Header from "../../../../../components/header";
import { useClient } from "../../../../../utils/client/clientContext";
import LoadingWheel from "../../../../../components/loadingWheel";
import EmployeesKpisTable, {
    initialEmployeesKpisColumnOrder,
} from "../../../../../tables/employeesKpisTable";
import Results from "../../../../../models/results";
import { useSort } from "../../../../../hooks/useSort";
import { fetchClientsKpis, fetchGeneralKpis } from "../../api/kpisApi";
import TabSelector from "../../../../../components/tabSelector";
import { kpiTabs } from "../tabs";

export interface EmployeeKpi {
    employee_id: string;
    employee_name: string;
    fba_shipment_time: string;
    merchant_order_time: string;
    received_time: string;
    reshipping_time_from_received: string;
    shipment_request_to_confirmation: string;
    requested_details_response_time: string;
    order_received_to_checked_in: string;
}

function AdminGeneralKpisPage() {
    const { user_id, orderCount } = useClient();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(false);

    const [employeeKpis, setEmployeeKpis] = useState<EmployeeKpi[]>([]);
    const [selectedEmployeeKpis, setSelectedEmployeeKpis] = useState<
        EmployeeKpi[]
    >([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchGeneralKpis();
            if (data.status === "success") {
                setEmployeeKpis(data.data.kpis);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">KPI's</span>
                    </div>
                </div>

                <TabSelector tabs={kpiTabs(orderCount)} />

                {!loading ? (
                    <EmployeesKpisTable
                        employeeKpis={employeeKpis}
                        results={results}
                        selectedEmployeeKpis={selectedEmployeeKpis}
                        setSelectedEmployeeKpis={setSelectedEmployeeKpis}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={() => {}}
                        emptyTableText="No records found"
                        columnOrder={initialEmployeesKpisColumnOrder}
                        hiddenHeaders={[]}
                    />
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminGeneralKpisPage;
