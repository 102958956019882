/** @format */

import { useState } from "react";

export const sortableColumns = [
    "created_at",
    "size",
    "brand_sku",
    "item_name",
    "order_number",
    "retailer",
    "fulfilled_by",
    "status",
    "quantity",
    "color",
    "asin",
    "amazon_sku",
    "unit_cost",
    "list_price",
    "quantity_remaining",
    "inbound",
    "inventory",
    "outbound",
    "date_shipped",
    "carrier",
    "date",
    "location",
    "tracking_number",
    "upc",
    "order_name",
    "payment_status",
    "invoice_date",
    "total",
    "total_saved",
    "full_name",
];

export interface SortConfig {
    key: string;
    order: "asc" | "desc";
}

export const useSort = (initialSort: SortConfig) => {
    const [sortConfig, setSortConfig] = useState<SortConfig>(initialSort);
    const [sortLoading, setSortLoading] = useState(false);

    // Toggle sort direction and update sortConfig
    const toggleSort = (key: string) => {
        setSortLoading(true);
        setSortConfig((prev) => {
            const newOrder =
                prev.key === key
                    ? prev.order === "asc"
                        ? "desc"
                        : "asc"
                    : "asc";
            return { key, order: newOrder };
        });
    };

    return { sortConfig, toggleSort, sortLoading, setSortLoading };
};
