/** @format */

import { useState, useEffect, useCallback } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import { ActionButton, SecondaryButton } from "../../../../components/buttons";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { FormSubmission } from "./page";
import { convertDateToEST } from "../../../../utils/date/date_formatter";
import { InputTextField } from "../../../../components/inputField";
import { editNotesFormSubmission } from "../api/leadsApi";

export function EditFormSubmissionModal({
    openEditFormSubmissionModal,
    setOpenEditFormSubmissionModal,
    selectedSubmission,
    handleRefetch,
}: {
    openEditFormSubmissionModal: boolean;
    setOpenEditFormSubmissionModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    selectedSubmission: FormSubmission | null;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const [notes, setNotes] = useState("");

    useEffect(() => {
        if (openEditFormSubmissionModal && selectedSubmission)
            setNotes(selectedSubmission.notes);
    }, [openEditFormSubmissionModal]);

    const handleUpdate = async () => {
        setLoading(true);
        const data = await editNotesFormSubmission(
            selectedSubmission?.form_submission_id || "",
            notes
        );
        if (data.status === "success") {
            showNotification("Updated form submission notes", "", "success");
            handleRefetch();
            setOpenEditFormSubmissionModal(false);
        } else {
            showNotification(
                "Failed to update for submission notes",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openEditFormSubmissionModal}
            onClose={() => setOpenEditFormSubmissionModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-5/6 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-5xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenEditFormSubmissionModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Edit form submission
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4">
                            <div className="grid grid-cols-2 gap-y-5 gap-x-3">
                                <div>
                                    <h4 className="text-sm text-gray-800 font-medium">
                                        Date created
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {convertDateToEST(
                                            selectedSubmission?.created_at || ""
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-800 font-medium">
                                        Name
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {selectedSubmission?.name}
                                    </p>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-800 font-medium">
                                        Company name
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {selectedSubmission?.company_name}
                                    </p>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-800 font-medium">
                                        Email
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {selectedSubmission?.email}
                                    </p>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-800 font-medium">
                                        Phone
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {selectedSubmission?.phone}
                                    </p>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-800 font-medium">
                                        Units/month
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {selectedSubmission?.units_a_month}
                                    </p>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-800 font-medium">
                                        Business details
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {selectedSubmission?.business_details}
                                    </p>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-800 font-medium">
                                        Type
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {selectedSubmission?.type}
                                    </p>
                                </div>
                                <div className="col-span-2">
                                    <InputTextField
                                        label="Notes"
                                        value={notes}
                                        onChange={(e) =>
                                            setNotes(e.target.value)
                                        }
                                    />
                                    <div className="flex flex-row-reverse pt-2">
                                        <ActionButton
                                            label="Save"
                                            handleClick={() => handleUpdate()}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between"></div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
