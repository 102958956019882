/** @format */

import { useState } from "react";
import { InputField } from "../../../components/inputField";
import LoadingWheel from "../../../components/loadingWheel";
import { AdminAccountData } from "../../../models/accountData";
import { capitalizeFirstLetter } from "../../../utils/strings/string";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
    addAdminAccount,
    deleteAdminAccount,
    updateCreditPerReferral,
} from "../api/settingsApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import { concat } from "lodash";
import { Employee } from "../../../models/employees";
import { addEmployee, removeEmployee } from "../employees/api/employeesApi";

function ManageReferralCredit({
    creditAmount,
    setCreditAmount,
    handleRefetch,
}: {
    creditAmount: string;
    setCreditAmount: React.Dispatch<React.SetStateAction<string>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const handleUpdate = async () => {
        setLoading(true);
        const data = await updateCreditPerReferral(creditAmount);
        if (data.status === "success") {
            showNotification(
                "Updated credit per referral amount",
                "",
                "success"
            );
            handleRefetch();
        } else {
            showNotification("Action failed.", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <div className="bg-white border border-gray-200 shadow-sm sm:rounded-lg lg:w-3/5">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Manage referral credit
                </h3>
                <div className="flex flex-col lg:flex-row lg:items-end lg:space-x-4 pt-3">
                    <div className="flex flex-row items-center space-x-4 w-full">
                        <InputField
                            label="Credit per referral"
                            value={creditAmount}
                            onChange={(e) => setCreditAmount(e.target.value)}
                            placeholder=""
                        />
                    </div>
                    <button
                        disabled={loading}
                        onClick={() => handleUpdate()}
                        className="mt-3 lg:w-44 w-full items-center justify-center rounded-md bg-accent px-3 h-9 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                    >
                        {loading ? (
                            <LoadingWheel color="white" size="small" />
                        ) : (
                            <>Update amount</>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ManageReferralCredit;
