/** @format */

import { useEffect, useState } from "react";
import LoadingWheel from "../../../components/loadingWheel";
import { useNotification } from "../../../utils/notification/notificationContext";
import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/16/solid";
import { CheckIcon } from "@heroicons/react/20/solid";
import SmallSelector from "../../../components/dropdowns/smallSelector";
import { orderTypes } from "../../../models/data";
import MediumSelector from "../../../components/dropdowns/mediumSelector";

export interface Printer {
    alerts: string;
    connection: string;
    description: string;
    printer: string;
    status: string;
}

function ManagePrinters() {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [printers, setPrinters] = useState<Printer[]>([]);

    const fetchPrinters = async () => {
        try {
            const response = await fetch("http://localhost:8000/printers", {
                method: "GET",
            });
            const data = await response.json();
            if (data.status === "success") {
                setPrinters(data.printers);

                if (data.selectedPrinter) {
                    const matchedPrinter = data.printers.find(
                        (printer: Printer) =>
                            printer.printer.toLowerCase() ===
                            data.selectedPrinter.toLowerCase()
                    );

                    setSelected({
                        id: data.selectedPrinter,
                        name: matchedPrinter
                            ? matchedPrinter.description
                            : "Unknown Printer",
                        value: data.selectedPrinter.toLowerCase(),
                    });
                }
            }
        } catch (error) {
            console.log("Error fetching printers:", error);
        }
    };

    useEffect(() => {
        let isMounted = true;
        fetchPrinters().then(() => {
            if (isMounted) setLoading(false);
        });
        return () => {
            isMounted = false;
        };
    }, []);

    const [selected, setSelected] = useState<{
        id: string;
        name: string;
        value: string;
    }>({ id: "0", name: "None", value: "none" });

    const printerOptions = [
        { id: "0", name: "None", value: "none" },
        ...printers.map((printer) => ({
            id: printer.printer,
            name: printer.description,
            value: printer.printer.toLowerCase(),
        })),
    ];

    useEffect(() => {
        const setPrinter = async (printerName: string) => {
            try {
                const response = await fetch(
                    "http://localhost:8000/set-printer",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ printerName }),
                    }
                );
                const data = await response.json();
                if (data.status === "success") {
                } else {
                    showNotification(
                        "Failed to set printer",
                        data.message,
                        "error"
                    );
                }
            } catch (error) {
                console.log(error);
            }
        };
        if (selected.value !== "none") {
            setPrinter(selected.value);
        }
    }, [selected]);

    return (
        <div className="bg-white border border-gray-200 shadow-sm sm:rounded-lg w-full">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Manage printer options (Urva Print)
                </h3>
                <div className="flex flex-col lg:flex-row lg:items-end lg:space-x-4 pt-3">
                    <div className="flex flex-col w-full">
                        <MediumSelector
                            selected={selected}
                            setSelected={setSelected}
                            selectorData={printerOptions}
                            width="w-64"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManagePrinters;
