/** @format */

import { useSearchParams } from "react-router-dom";
import { TrackingInfo } from "../models/trackings";
import { TrackingStatus } from "../models/order";
import { useState } from "react";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { SortLoading } from "../components/loadingWheel";
import { TrashIcon } from "@heroicons/react/24/outline";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { FormSubmission } from "../pages/admin/leads/form-submissions/page";
import { AuditLog } from "../pages/admin/audits/page";
import { HoverExpandedText } from "../pages/admin/received/markReceivedOrder";
import { EmployeeKpi } from "../pages/admin/audits/kpis/employees/page";

export const initialEmployeesKpisColumnOrder = [
    {
        key: "employee_name",
        label: "Employee name",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "avg_fba_shipment_time",
        label: "Average FBA Time",
        isSortable: false,
        className: "sm:min-w-10 min-w-10 pl-4",
    },
    {
        key: "avg_merchant_order_time",
        label: "Average Merchant Order Time",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    {
        key: "avg_received_time",
        label: "Average Receiving Time",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    {
        key: "avg_reshipping_time_from_received",
        label: "Average Reshipping Time",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    // {
    //     key: "avg_shipment_request_to_confirmation",
    //     label: "Average Shipment Requested to Shipped",
    //     isSortable: false,
    //     className: "sm:min-w-32 min-w-32 pl-4",
    // },
    // {
    //     key: "avg_requested_details_response_time",
    //     label: "Average Details Response",
    //     isSortable: false,
    //     className: "sm:min-w-32 min-w-32 pl-4",
    // },
    // {
    //     key: "avg_order_received_to_checked_in",
    //     label: "Average Received to Checked In",
    //     isSortable: false,
    //     className: "sm:min-w-32 min-w-32 pl-4",
    // },
];

interface EmployeesKpisTableProps {
    employeeKpis: EmployeeKpi[];
    results: Results;
    selectedEmployeeKpis: EmployeeKpi[];
    setSelectedEmployeeKpis: React.Dispatch<
        React.SetStateAction<EmployeeKpi[]>
    >;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder: Column[];
    hiddenHeaders: string[];
}

function EmployeesKpisTable({
    employeeKpis,
    results,
    selectedEmployeeKpis,
    setSelectedEmployeeKpis,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    hiddenHeaders,
}: EmployeesKpisTableProps) {
    const [searchParams] = useSearchParams();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTrackingId, setSelectedTrackingId] = useState<string | null>(
        ""
    );
    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const displayedHeaders = columnOrder.filter(
        (header) => !hiddenHeaders.includes(header.key)
    );

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedEmployeeKpis(employeeKpis);
        } else {
            setSelectedEmployeeKpis([]);
        }
    };

    const handleCheckboxChange = (employeeKpi: EmployeeKpi) => {
        const employeeId = employeeKpi.employee_id;
        if (selectedEmployeeKpis.some((o) => o.employee_id === employeeId)) {
            setSelectedEmployeeKpis(
                selectedEmployeeKpis.filter((o) => o.employee_id !== employeeId)
            );
        } else {
            setSelectedEmployeeKpis([...selectedEmployeeKpis, employeeKpi]);
        }
    };

    return (
        <div className="flex flex-col pt-3">
            {employeeKpis && employeeKpis.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.key === "select" ? (
                                                    <input
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onChange={
                                                            handleSelectAll
                                                        }
                                                        checked={
                                                            selectedEmployeeKpis &&
                                                            employeeKpis.length ===
                                                                selectedEmployeeKpis.length &&
                                                            employeeKpis.length >
                                                                0
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        {header.label}
                                                        <ColumnSort
                                                            header={header}
                                                            sortConfig={
                                                                sortConfig
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {employeeKpis.map(
                                    (
                                        employeeKpi: EmployeeKpi,
                                        index: number
                                    ) => (
                                        <tr
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500"
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (header: Column) => (
                                                        <>
                                                            {header.key ===
                                                            "select" ? (
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                                        onChange={() =>
                                                                            handleCheckboxChange(
                                                                                employeeKpi
                                                                            )
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            e.stopPropagation()
                                                                        }
                                                                        checked={selectedEmployeeKpis.some(
                                                                            (
                                                                                o
                                                                            ) =>
                                                                                o.employee_id ===
                                                                                employeeKpi.employee_id
                                                                        )}
                                                                    />
                                                                </td>
                                                            ) : header.key ===
                                                              "created_at" ? (
                                                                <td className="py-2.5 text-sm whitespace-nowrap">
                                                                    {convertDateToEST(
                                                                        String(
                                                                            employeeKpi[
                                                                                header.key as keyof EmployeeKpi
                                                                            ]
                                                                        )
                                                                    )}
                                                                </td>
                                                            ) : false ? (
                                                                <td>
                                                                    <div className="relative group">
                                                                        Show it
                                                                        <HoverExpandedText
                                                                            show={
                                                                                true
                                                                            }
                                                                            text={JSON.stringify(
                                                                                employeeKpi[
                                                                                    header.key as keyof EmployeeKpi
                                                                                ]
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className={`py-2.5 text-sm whitespace-nowrap ${
                                                                        header.key !==
                                                                        "employee_name"
                                                                            ? "pl-4"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {String(
                                                                        typeof employeeKpi[
                                                                            header.key as keyof EmployeeKpi
                                                                        ] ===
                                                                            "object"
                                                                            ? JSON.stringify(
                                                                                  employeeKpi[
                                                                                      header.key as keyof EmployeeKpi
                                                                                  ]
                                                                              )
                                                                            : employeeKpi[
                                                                                  header.key as keyof EmployeeKpi
                                                                              ]
                                                                    )}
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/kpis?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/kpis?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}
        </div>
    );
}

export default EmployeesKpisTable;
