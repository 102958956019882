/** @format */

import { useCallback, useEffect, useRef, useState } from "react";
import AdminSearchBar from "../../../components/admin/searchBar";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import OrdersTable, { getTableHeaders } from "../../../tables/ordersTable";
import { Order } from "../../../models/order";
import Results from "../../../models/results";
import { fetchClientOrders } from "../api/unitsApi";
import { useSearchParams } from "react-router-dom";
import { useSort } from "../../../hooks/useSort";
import { useNotification } from "../../../utils/notification/notificationContext";
import LoadingWheel from "../../../components/loadingWheel";
import ModifyInboundOrderModal from "../clients/client-data/shipments/inbound-management/modifyInboundOrderModal";
import { ClientFilterPopup } from "../clients/filters/clientFilter";
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { orderTypes } from "../../../models/data";
import SmallSelector from "../../../components/dropdowns/smallSelector";
import TrackingsTable, {
    initialTrackingsColumnOrder,
} from "../../../tables/trackingsTable";
import { TrackingInfo } from "../../../models/trackings";
import { fetchTrackings } from "../api/trackingsApi";
import ClientDrawer from "../clients/clientDrawer";
import { FaRegEdit } from "react-icons/fa";
import { UserIcon } from "@heroicons/react/24/outline";
import { useClient } from "../../../utils/client/clientContext";
import { Column } from "../../../tables/models";
import { handleMoveDown, handleMoveUp } from "../../../tables/actions";
import ColumnOrderDropdown from "../../../tables/columnDragPopup";
import ManagePrinters from "../settings/managePrinters";

export default function AdminInboundPage() {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        client,
        setClient,
        clientLoading,
        user_id,
        setUserId,
        orderCount,
        handleOrderCountRefetch,
    } = useClient();

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);

    const [orders, setOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [trackings, setTrackings] = useState<TrackingInfo[]>([]);
    const [trackingsResults, setTrackingsResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const fetchData = async (orderType: string) => {
        if (orderType === "units") {
            const data = await fetchClientOrders(
                user_id,
                "inbound",
                undefined,
                searchParams.get("offset")
                    ? Number(searchParams.get("offset"))
                    : 0,
                searchParams.get("limit")
                    ? Number(searchParams.get("limit"))
                    : 200,
                sortConfig
            );
            if (data.status === "success") {
                setOrders(data.data.orders);
                setResults(data.data.results);
            } else {
                showNotification(
                    "Failed fetching orders",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
            setSortLoading(false);
            setSelectedOrders([]);
        } else if (orderType === "trackings") {
            const data = await fetchTrackings(
                user_id,
                searchParams.get("offset")
                    ? Number(searchParams.get("offset"))
                    : 0,
                searchParams.get("limit")
                    ? Number(searchParams.get("limit"))
                    : 200,
                "inbound",
                sortConfig
            );
            if (data.status === "success") {
                setTrackings(data.data.trackings);
                setTrackingsResults(data.data.results);
            } else {
                showNotification(
                    "Failed fetching orders",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
            setSortLoading(false);
        }
    };

    const triggerRefetch = useCallback(() => {
        setLoading(true);
        const controller = new AbortController();
        fetchData(selectedOrderType.value);
        hasMounted.current = true;

        return () => {
            controller.abort();
        };
    }, [sortConfig, user_id, selectedOrderType]);

    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) {
            triggerRefetch();
        }
    }, [sortConfig, user_id, selectedOrderType]);

    const handleRefetch = (fromSearch: boolean = false) => {
        if (keywords.length < 1 && inputValue === "") {
            triggerRefetch();
        } else {
            setRefetchSearchedData((prev) => !prev);
        }
        if (!fromSearch) {
            handleOrderCountRefetch();
        }
    };

    useEffect(() => {
        fetchData(selectedOrderType.value);
        hasMounted.current = true;
    }, []);

    const [openModifyInboundOrder, setOpenModifyInboundOrder] = useState(false);

    const clientFilterRef = useRef<HTMLButtonElement>(null);

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const toggleFilter = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    const [clientDrawerOpen, setClientDrawerOpen] = useState(false);

    const [totalSum, setTotalSum] = useState("");

    const [refetchSearchedData, setRefetchSearchedData] = useState(false);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders(selectedOrderType.value)
    );
    const hiddenHeaders: string[] = [
        "input_shipping_quantity",
        "remove_selected_row",
        "input_shelf_location",
        "location",
        "qa",
    ];

    const [trackingsColumnOrder, setTrackingsColumnOrder] = useState<Column[]>(
        initialTrackingsColumnOrder
    );

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center">
                    <span className="text-2xl font-bold">Inbound</span>
                    <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                        {orderCount?.inbound}
                    </span>
                </div>

                <div className="flex flex-row items-center space-x-4 justify-between pt-4">
                    <div className="flex flex-row items-center space-x-4">
                        <SmallSelector
                            selected={selectedOrderType}
                            setSelected={setSelectedOrderType}
                            selectorData={orderTypes}
                        />
                        <div className="relative">
                            <button
                                ref={clientFilterRef}
                                onClick={toggleFilter}
                                className="text-xs text-gray-600 hover:bg-gray-50 flex flex-row items-center rounded-md shadow-sm border border-gray-300 px-2 h-7 divide-x gap-x-2"
                            >
                                <div className="flex flex-row items-center space-x-2">
                                    {!user_id ? (
                                        <PencilSquareIcon className="w-4 h-4" />
                                    ) : (
                                        <XCircleIcon
                                            className="w-4 h-4 text-red-500 hover:text-red-400"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                searchParams.delete("user_id");
                                                window.history.replaceState(
                                                    null,
                                                    "",
                                                    `?${searchParams.toString()}`
                                                );
                                                setUserId(null);
                                            }}
                                        />
                                    )}
                                    <span>Client ID</span>
                                </div>
                                {user_id && (
                                    <span className="font-medium text-accent pl-2">
                                        {user_id} -{" "}
                                        <span className="text-gray-600">
                                            {client &&
                                                client.first_name +
                                                    " " +
                                                    client.last_name}
                                        </span>
                                    </span>
                                )}
                            </button>
                            <ClientFilterPopup
                                isOpen={isFilterOpen}
                                onClose={() => setIsFilterOpen(false)}
                                taxButtonRef={clientFilterRef}
                                user_id={user_id}
                                setUserId={setUserId}
                            />
                        </div>
                        {user_id && (
                            <button
                                onClick={() => setClientDrawerOpen(true)}
                                className="flex h-7 flex-row items-center text-xs px-2 border border-gray-300 shadow-sm rounded-md text-black"
                            >
                                <UserIcon className="w-4 h-4" />{" "}
                                <span className="pl-1.5">View client</span>
                            </button>
                        )}
                        <ColumnOrderDropdown
                            columnOrder={
                                selectedOrderType.value === "units"
                                    ? unitsColumnOrder
                                    : trackingsColumnOrder
                            }
                            setColumnOrder={
                                selectedOrderType.value === "units"
                                    ? setUnitsColumnOrder
                                    : setTrackingsColumnOrder
                            }
                            localStorageKey={
                                selectedOrderType.value === "units"
                                    ? "unitsColumnOrder"
                                    : "trackingsColumnOrder"
                            }
                            selectedOrderType={selectedOrderType}
                            initialColumnOrder={
                                selectedOrderType.value === "units"
                                    ? getTableHeaders("inbound")
                                    : initialTrackingsColumnOrder
                            }
                        />
                    </div>
                    <div className="flex flex-row items-center space-x-4 divide-x divide-gray-200">
                        {totalSum !== "" && (
                            <span className="text-gray-700 text-sm">
                                Sum: {totalSum}
                            </span>
                        )}
                        {selectedOrders && selectedOrders?.length > 0 && (
                            <span className="text-gray-700 text-sm pl-2">
                                {selectedOrders.length} shipment
                                {selectedOrders.length > 1 && "s"} selected
                            </span>
                        )}
                    </div>
                </div>

                <div className="flex flex-row items-start space-x-4 pt-3">
                    <AdminSearchBar
                        setData={setOrders}
                        setLoading={setLoading}
                        handleRefetch={handleRefetch}
                        page="inbound"
                        sub_page={selectedOrderType.value}
                        user_id={user_id}
                        keywords={keywords}
                        setKeywords={setKeywords}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        refetchSearchedData={refetchSearchedData}
                    />
                    <button
                        disabled={selectedOrders.length === 0}
                        onClick={() => setOpenModifyInboundOrder(true)}
                        className={`${
                            selectedOrders.length === 0
                                ? "bg-accentdim"
                                : "bg-accent hover:bg-accenthighlight"
                        } text-white font-semibold px-5 text-xs rounded-md h-8 whitespace-nowrap`}
                    >
                        Modify Shipment Status
                    </button>
                </div>

                {!loading ? (
                    <>
                        {selectedOrderType.value === "units" ? (
                            <OrdersTable
                                orderStatus="inbound"
                                orders={orders}
                                results={results}
                                selectedOrders={selectedOrders}
                                setSelectedOrders={setSelectedOrders}
                                sortLoading={sortLoading}
                                sortConfig={sortConfig}
                                toggleSort={toggleSort}
                                handleRefetch={() => {}}
                                client_type="admin"
                                setTotalSum={setTotalSum}
                                columnOrder={unitsColumnOrder}
                                hiddenHeaders={hiddenHeaders}
                            />
                        ) : (
                            <TrackingsTable
                                trackings={trackings}
                                results={trackingsResults}
                                trackingType="inbound"
                                sortLoading={sortLoading}
                                sortConfig={sortConfig}
                                toggleSort={toggleSort}
                                handleRefetch={handleRefetch}
                                emptyTableText={
                                    "You'll see the trackings that are en route to the warehouse here."
                                }
                                columnOrder={trackingsColumnOrder}
                                hiddenHeaders={["actions"]}
                            />
                        )}
                    </>
                ) : (
                    <div className="flex flex-grow items-center pb-44 justify-center">
                        {loading ? (
                            <LoadingWheel />
                        ) : (
                            <span className="text-sm text-gray-500">
                                User has no inbound orders
                            </span>
                        )}
                    </div>
                )}
            </div>
            <ModifyInboundOrderModal
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                openModifyInboundOrder={openModifyInboundOrder}
                setOpenModifyInboundOrder={setOpenModifyInboundOrder}
                handleFullRefetch={handleRefetch}
            />

            <ClientDrawer
                user_id={user_id || ""}
                open={clientDrawerOpen}
                setOpen={setClientDrawerOpen}
                client={client}
                setClient={setClient}
            />
        </div>
    );
}
