/** @format */

import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useClient } from "../../../utils/client/clientContext";
import { useNotification } from "../../../utils/notification/notificationContext";
import { useSort } from "../../../hooks/useSort";
import LoadingWheel from "../../../components/loadingWheel";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import Results from "../../../models/results";
import { fetchAudits } from "./api/auditsApi";
import AuditsTable, {
    initialAuditsColumnOrder,
} from "../../../tables/auditsTable";
import AdminSearchBar from "../../../components/admin/searchBar";

export interface AuditLog {
    audit_id: string;
    table_name: string;
    operation: string;
    old_values: Record<string, any>;
    new_values: Record<string, any>;
    modified_by: string;
    modified_at: string;
    row_id: string;
}

function AdminAuditsPage() {
    const { user_id, orderCount } = useClient();

    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [audits, setAudits] = useState<AuditLog[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [selectedAudits, setSelectedAudits] = useState<AuditLog[]>([]);

    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const data = await fetchAudits(searchParams, sortConfig);
        if (data.status === "success") {
            setAudits(data.data.audits);
            setResults(data.data.results);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
        setLoading(false);
    };

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    const [refetchSearchedData, setRefetchSearchedData] = useState(false);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">Audits</span>
                    </div>
                </div>
                <div className="pt-3">
                    <AdminSearchBar
                        setData={setAudits}
                        setLoading={setLoading}
                        handleRefetch={handleRefetch}
                        page="audits"
                        sub_page={""}
                        user_id={null}
                        keywords={keywords}
                        setKeywords={setKeywords}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        refetchSearchedData={refetchSearchedData}
                    />
                </div>

                {!loading ? (
                    <AuditsTable
                        audits={audits}
                        results={results}
                        selectedAudits={selectedAudits}
                        setSelectedAudits={setSelectedAudits}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={() => {}}
                        emptyTableText="No records found"
                        columnOrder={initialAuditsColumnOrder}
                        hiddenHeaders={[]}
                    />
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminAuditsPage;
