import Cookies from "js-cookie";

export const sendPrintJob = async (printerName: string) => {
    try {
        const response = await fetch(
            "http://localhost:8000/set-printer",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ printerName }),
            }
        );
        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error) } }; 
    }
}

export const fetchAndPrintLabel = async (
    showNotification: (title: string, subtitle?: string, type?: "success" | "error" | "successPrint") => void,
    shipmentId: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    fileUrl?: string
) => {
    try {
        setLoading(true)
            if (!fileUrl) {

                const response = await fetch(
                    `https://api.urvafreight.com/api/v1/shipments/requested/label/${shipmentId}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwt")}`,
                        },
                    }
                );

            if (!response.ok) {
                throw new Error(`Failed to retrieve PDF: ${response.statusText}`);
            }

            const pdfBlob = await response.blob();
            const formData = new FormData();
            formData.append("file", pdfBlob, `shipment_${shipmentId}.pdf`);

            const printResponse = await fetch("http://localhost:8000/print", {
                method: "POST",
                body: formData,
            });

            const printData = await printResponse.json();

            if (printData.status !== "success") {
                showNotification("Failed to queue print job.", "", "error")
                setLoading(false)
                return
            }
        } else {
            const printResponse = await fetch("http://localhost:8000/print", {
                method: "POST",
                body: JSON.stringify({fileUrl}),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const printData = await printResponse.json();

            if (printData.status !== "success") {
                showNotification("Failed to queue print job.", "", "error")
                setLoading(false)
                return
            }
        }

        showNotification("Print job successfully queued.", "", "successPrint")
        setLoading(false)
    } catch (error) {
        showNotification("Failed to queue print job.", "", "error")
        setLoading(false)
    }
};