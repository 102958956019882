/** @format */
import { LuArrowUpRight } from "react-icons/lu";

import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { OrderFulfilledBy } from "../models/order";

export function StatusTag({
    status,
    quantity_received,
    quantity,
    fulfilled_by,
    handleClick,
    main_status,
    shipping_labels,
}: {
    status: string;
    quantity_received: number;
    quantity: number;
    fulfilled_by: OrderFulfilledBy;
    handleClick?: (e: any) => void;
    main_status?: string;
    shipping_labels?: string[];
}) {
    if (
        fulfilled_by === "parcel-forwarding" &&
        shipping_labels &&
        shipping_labels.length < 1
    ) {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-blue-600 font-semibold bg-blue-100 py-1.5 px-2 rounded-md border border-transparent hover:bg-blue-200 hover:border hover:border-blue-400"
            >
                Upload Label
            </span>
        );
    }
    if (main_status === "outbound") {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md border border-transparent hover:bg-green-200 hover:border hover:border-green-400"
            >
                Fully Shipped
            </span>
        );
    }
    if (status === "fully_received") {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md border border-transparent hover:bg-green-200 hover:border hover:border-green-400"
            >
                Fully Received
            </span>
        );
    } else if (status === "partially_received") {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-yellow-600 py-1.5 inline whitespace-nowrap font-semibold bg-yellow-100 px-2 rounded-md border border-transparent hover:bg-yellow-200 hover:border hover:border-yellow-400"
            >
                {quantity_received}/{quantity} received
                <span className="inline-block align-middle">
                    <LuArrowUpRight className="h-4 w-4" />
                </span>
            </span>
        );
    } else {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-gray-600 font-semibold bg-gray-100 py-1.5 px-2 rounded-md border border-transparent hover:bg-gray-200 hover:border hover:border-gray-400"
            >
                Pending
            </span>
        );
    }
}

export function PaymentStatusTag({
    status,
    value,
}: {
    status: string;
    value?: string;
}) {
    if (status === "paid") {
        return (
            <span className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md">
                Paid
            </span>
        );
    } else if (status === "unpaid") {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md">
                Unpaid {value ? ` - ${value}` : ""}
            </span>
        );
    } else {
        return (
            <span className="text-gray-600 font-semibold bg-gray-100 py-1.5 px-2 rounded-md">
                Open
            </span>
        );
    }
}

export function ShipmentStatusTag({
    status,
    value,
}: {
    status: string;
    value?: string;
}) {
    if (status === "shipped") {
        return (
            <span className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md">
                Shipped
            </span>
        );
    } else if (status === "requested" || status === "pending") {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md">
                Requested
            </span>
        );
    } else {
        return (
            <span className="text-gray-600 font-semibold bg-gray-100 py-1.5 px-2 rounded-md">
                Open
            </span>
        );
    }
}

export function ReferralStatusTag({
    credit_approved,
}: {
    credit_approved: boolean;
}) {
    if (credit_approved) {
        return (
            <span className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md">
                Approved
            </span>
        );
    } else {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md">
                Pending
            </span>
        );
    }
}

export function AttentionNeededTag({
    shipping_status,
}: {
    shipping_status: string;
}) {
    if (shipping_status === "fba_ready") {
        return (
            <div
                className={`h-7 w-fit flex items-center gap-2 px-4 text-xs border border-green-300 bg-green-50 shadow-sm rounded-md text-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
            >
                FBA Shipment Ready
            </div>
        );
    } else if (shipping_status === "no_shipments") {
        return (
            <div
                className={`h-7 w-fit flex items-center gap-2 px-4 text-xs border border-gray-300 shadow-sm rounded-md text-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
            >
                No Inventory
            </div>
        );
    } else if (shipping_status === "fba_not_ready") {
        return (
            <div
                className={`h-7 w-fit flex items-center gap-2 px-4 text-xs border border-gray-300 shadow-sm rounded-md text-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
            >
                Not Ready
            </div>
        );
    } else if (shipping_status === "requested_ready") {
        return (
            <div
                className={`h-7 w-fit flex items-center gap-2 px-4 text-xs border border-green-300 bg-green-50 shadow-sm rounded-md text-black focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
            >
                Requested shipment ready
            </div>
        );
    } else {
        return <></>;
    }
}

export function ReceivedOrderStatusTag({
    status,
    handleClick,
}: {
    status: "received" | "checked-in";
    handleClick?: (e: any) => void;
}) {
    if (status === "received") {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-gray-600 font-semibold bg-gray-100 py-1.5 px-2 rounded-md cursor-pointer border border-transparent hover:bg-gray-200 hover:border hover:border-gray-400"
            >
                Received
            </span>
        );
    } else {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md border border-transparent hover:bg-green-200 hover:border hover:border-green-400"
            >
                Checked In
            </span>
        );
    }
}
