/** @format */
import { v4 as uuidv4 } from "uuid";
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { BsUpcScan } from "react-icons/bs";
import {
    PlusCircleIcon,
    TrashIcon,
    XCircleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { fetchClientOrders } from "../../../../api/unitsApi";
import { useParams } from "react-router-dom";
import LoadingWheel from "../../../../../../components/loadingWheel";
import ClientOutboundTable from "../client-orders-data/clientOutboundTable";
import Shipment, {
    serviceOptions,
    ShipmentService,
    ShipmentTracking,
} from "../../../../../../models/shipments";
import {
    convertToDayjsFromString,
    formatDateFromDb,
    formatDateFromObject,
} from "../../../../../../utils/date/date_formatter";
import { Order } from "../../../../../../models/order";
import {
    addTrackingToShipment,
    deleteShipment,
    deleteTrackingFromShipment,
    updateRequestedShipmentStatus,
    updateRequestedShipmentStatusToPending,
    updateShipment,
} from "../../../../api/shipmentsApi";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import {
    downloadShipmentPdf,
    getShipmentPdfUrl,
} from "../../../../api/labelsApi";
import Results from "../../../../../../models/results";
import SingleCalendar from "../../../../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import { Box } from "../../../../../../models/adminDashboard";
import { fetchBoxes } from "../../../../api/settingsApi";
import { formatMoney } from "../../../../../../utils/price/formatMoney";
import { LabelModal } from "../../../../../client/inventory/inventory-data/request-shipment/labelModal";
import { Label } from "../../../../../../models/label";
import { ScannedBarcode } from "../../../../../../models/scannedBarcode";
import { useClient } from "../../../../../../utils/client/clientContext";
import { cleanTrackingNumber } from "../../../../../../utils/carriers/cleanTrackingNumber";
import {
    ActionButton,
    DeleteButton,
} from "../../../../../../components/buttons";
import CopyButton from "../../../../../../components/copyButton";
import AdminSearchBar from "../../../../../../components/admin/searchBar";
import ServicesPopup from "../../../../outbound/components/servicesPopup";
import BoxesPopup from "../../../../outbound/components/boxesPopup";
import { fetchAndPrintLabel } from "../../../../api/printerApi";
import useKpiTracker from "../../../../../../hooks/useKpiTracker";

export default function OutboundOrdersModal({
    shipment,
    openOutboundOrders,
    setOpenOutboundOrders,
    handleFullRefetch,
    setShipments,
}: {
    shipment: Shipment | null;
    openOutboundOrders: boolean;
    setOpenOutboundOrders: React.Dispatch<React.SetStateAction<boolean>>;
    handleFullRefetch: () => void;
    setShipments?: React.Dispatch<React.SetStateAction<Shipment[]>>;
}) {
    const { user_id } = useClient();

    const { startKpi, endKpi, actionId } = useKpiTracker();

    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const [outboundOrders, setOutboundOrders] = useState<Order[]>([]);
    const [shipmentTrackings, setShipmentTrackings] = useState<
        ShipmentTracking[]
    >([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchOrders, setRefetchOrders] = useState(0);
    const handleOrdersRefetch = () => {
        setRefetchOrders((prev) => prev + 1);
    };

    const [offset, setOffset] = useState(0);

    const [downloadLoading, setDownloadLoading] = useState(false);

    const [openLabelModal, setOpenLabelModal] = useState(false);
    const [selectedViewingLabel, setSelectedViewingLabel] =
        useState<Label | null>(null);

    const updateOrderStatus = async () => {
        const data = await updateRequestedShipmentStatusToPending(
            shipment?.shipment_id
        );
        if (data.status === "success") {
            showNotification(
                "Shipment marked as pending",
                "Client can no longer unrequest this shipment.",
                "success"
            );
        } else {
            showNotification(
                "Failed to update to pending status",
                "Please leave and enter this shipment again.",
                "error"
            );
        }
    };

    const fetchData = async () => {
        const data = await fetchClientOrders(
            user_id,
            "outbound",
            shipment?.shipment_id,
            offset
        );
        if (data.status === "success") {
            setOutboundOrders(data.data.orders ? data.data.orders : []);
            setShipmentTrackings(data.data.trackings);
            setResults(data.data.results);
        }
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        setEditedName(shipment?.name || "");
        setEditedDate(convertToDayjsFromString(shipment?.date || ""));
        setEditedNotes(shipment?.notes || "");
        setEditedBoxes(shipment?.boxes || []);

        setSelectedServices(shipment?.services || []);
        setSelectedBoxes(shipment?.boxes || []);

        setSelectedViewingLabel(null);
        if (openOutboundOrders || offset) {
            fetchData();
        }
        if (shipment && openOutboundOrders && setShipments) {
            if (
                shipment.admin_status !== "pending" &&
                shipment.admin_status !== "shipped" &&
                shipment.status === "requested"
            ) {
                updateOrderStatus();
                setShipments((prevShipments) =>
                    prevShipments.map((s) =>
                        s.shipment_id === shipment.shipment_id
                            ? { ...s, admin_status: "pending" }
                            : s
                    )
                );
            }
        }
        if (!openOutboundOrders) {
            setEditingShipment(false);
            setScannedUpcs([]);
            setVerifiedOrderIds([]);
            setCurrentUPCInput("");
            setErrorMatchingUpcToOrder(false);
            setSelectedBoxes([]);
            setSelectedServices([]);
        } else {
            if (
                shipment &&
                shipment.status === "requested" &&
                shipment.admin_status !== "shipped"
            ) {
                startKpi("merchant_order_time");
            }
        }
        console.log(openOutboundOrders);
        console.log(offset);
        console.log(refetchOrders);
    }, [openOutboundOrders, offset, refetchOrders]);

    const handleDeleteShipment = async () => {
        setDeleteLoading(true);
        const data = await deleteShipment(shipment?.shipment_id);
        if (data.status === "success") {
            setOpenOutboundOrders(false);
            showNotification(
                "Deleted shipment",
                "Orders returned to inventory",
                "success"
            );
            handleFullRefetch();
        } else {
            showNotification(
                "Failed to deleted shipment",
                data.message,
                "error"
            );
        }
        setDeleteLoading(false);
    };

    const [printLoading, setPrintLoading] = useState(false);
    const handleDownload = async () => {
        setDownloadLoading(true);
        const success = await downloadShipmentPdf(shipment?.shipment_id);
        if (success) {
        } else {
            showNotification("Failed to download pdf", "", "error");
        }
        setDownloadLoading(false);
    };

    const [viewingLabelLoading, setViewingLabelLoading] = useState(false);
    const handleViewShipmentPdf = async () => {
        if (!selectedViewingLabel) {
            setViewingLabelLoading(true);
            const pdfUrl = await getShipmentPdfUrl(shipment?.shipment_id);
            if (pdfUrl) {
                setSelectedViewingLabel({
                    label_id: "",
                    public_url: pdfUrl,
                    name: "",
                    used_pages: [],
                });
            }
            setViewingLabelLoading(false);
        } else {
            setOpenLabelModal(true);
        }
    };

    useEffect(() => {
        if (selectedViewingLabel) {
            setOpenLabelModal(true);
        }
    }, [selectedViewingLabel]);

    const handleToggleStatus = async () => {
        setStatusLoading(true);

        const data = await updateRequestedShipmentStatus(
            shipment?.shipment_id,
            selectedBoxes,
            selectedServices
        );
        if (data.status === "success") {
            showNotification("Updated status of shipment", "", "success");
            setOpenOutboundOrders(false);
            setSelectedBoxes([]);
            setSelectedServices([]);
            handleFullRefetch();
            if (shipment) {
                endKpi(
                    shipment.shipment_id,
                    "shipments",
                    outboundOrders.reduce(
                        (sum, order) => sum + (order.quantity_shipped || 0),
                        0
                    )
                );
            }
        } else {
            showNotification("Failed to update status", data.message, "error");
        }
        setStatusLoading(false);
    };

    const [editingShipment, setEditingShipment] = useState(false);
    const [editedName, setEditedName] = useState("");
    const [editedDate, setEditedDate] = useState<Dayjs>(dayjs());
    const [editedNotes, setEditedNotes] = useState("");
    const [editedBoxes, setEditedBoxes] = useState<Box[]>([]);
    const [deletedBoxes, setDeletedBoxes] = useState<string[]>([]);

    const [editLoading, setEditLoading] = useState(false);
    const handleUpdate = async (markShipped: boolean = false) => {
        setEditLoading(true);
        if (markShipped) setStatusLoading(true);

        const data = await updateShipment(
            shipment?.shipment_id,
            editedName,
            formatDateFromObject(editedDate),
            editedNotes,
            selectedBoxes,
            selectedServices,
            markShipped
        );
        if (data.status === "success") {
            showNotification("Updated shipment", "", "success");
            setOpenOutboundOrders(false);
            handleFullRefetch();
        } else {
            showNotification(
                "Failed to update shipment",
                data.message,
                "error"
            );
        }
        setEditLoading(false);
        setStatusLoading(false);
    };

    useEffect(() => {
        setEditedName(shipment?.name || "");
        setEditedDate(convertToDayjsFromString(shipment?.date || ""));
        setEditedNotes(shipment?.notes || "");
        setSelectedServices(shipment?.services || []);
        setQuantities({});
    }, [editingShipment]);

    const [boxesLoading, setBoxesLoading] = useState(false);
    const [boxes, setBoxes] = useState<Box[]>([]);
    const [selectedBoxes, setSelectedBoxes] = useState<Box[]>([]);
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

    useEffect(() => {
        if (openOutboundOrders) {
            const fetchData = async () => {
                setBoxesLoading(true);
                const data = await fetchBoxes();
                if (data.status === "success") {
                    setBoxes(data.data.boxes);
                } else {
                    showNotification(
                        "Failed to fetch boxes",
                        data.message,
                        "error"
                    );
                }
                setBoxesLoading(false);
            };
            fetchData();
        }
    }, [openOutboundOrders]);

    const [selectedServices, setSelectedServices] = useState<ShipmentService[]>(
        []
    );

    const [scannedBarcodes, setScannedBarcodes] = useState<ScannedBarcode[]>(
        []
    );
    const [currentInput, setCurrentInput] = useState<string>("");

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentInput.trim()) {
            e.preventDefault();

            const [trackingNumber] = cleanTrackingNumber(currentInput.trim());

            const newScannedBarcode: ScannedBarcode = {
                id: uuidv4(), // Generate a unique ID
                barcode: trackingNumber,
                dateScanned: new Date().toLocaleString(),

                isLoading: false, // Set loading to true initially
            };
            setScannedBarcodes((prev) => [...prev, newScannedBarcode]);

            handleAddTracking(newScannedBarcode);

            setCurrentInput("");
        }
    };

    const handleAddTracking = async (scannedBarcode: ScannedBarcode) => {
        const data = await addTrackingToShipment(
            shipment?.shipment_id || "",
            scannedBarcode
        );
        if (data.status === "success") {
            const newTracking: ShipmentTracking = {
                tracking_id: data.data.tracking_id,
                carrier: data.data.carrier,
                tracking: data.data.tracking,
                tracking_url: data.data.tracking_url,
            };
            setShipmentTrackings([...shipmentTrackings, newTracking]);
        } else {
            showNotification("Failed to add tracking", data.message, "error");
        }
    };

    const [deletingTrackings, setDeletingTrackings] = useState<string[]>([]);

    const handleDeleteTracking = async (tracking_id: string) => {
        setDeletingTrackings([...deletingTrackings, tracking_id]);
        const data = await deleteTrackingFromShipment(
            shipment?.shipment_id || "",
            tracking_id
        );
        if (data.status === "success") {
            setShipmentTrackings(
                shipmentTrackings.filter(
                    (tracking) => tracking.tracking_id !== tracking_id
                )
            );
            setDeletingTrackings(
                deletingTrackings.filter((tracking) => tracking !== tracking_id)
            );
        } else {
            showNotification("Failed to add tracking", data.message, "error");
        }
    };

    const [scannedUPCs, setScannedUpcs] = useState<string[]>([]);
    const [verifiedOrderIds, setVerifiedOrderIds] = useState<string[]>([]);
    const [currentUPCInput, setCurrentUPCInput] = useState<string>("");
    const [errorMatchingUpcToOrder, setErrorMatchingUpcToOrder] =
        useState(false);

    const handleUpcInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentUPCInput(e.target.value);
    };

    const confirmUpcMismatch = () => {
        setScannedUpcs((prevUPCs) => [...prevUPCs, currentUPCInput]);
        setCurrentUPCInput("");
        setErrorMatchingUpcToOrder(false);
    };

    const handleUpcKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentUPCInput.trim()) {
            e.preventDefault();

            const scannedUPC = currentUPCInput.trim();

            const matchingOrders = outboundOrders.filter(
                (order) =>
                    order.upc === scannedUPC &&
                    !verifiedOrderIds.includes(order.order_id)
            );

            if (matchingOrders.length > 0) {
                setVerifiedOrderIds((prevIds) => [
                    ...prevIds,
                    ...matchingOrders.map((order) => order.order_id),
                ]);

                setScannedUpcs((prevUPCs) => [
                    ...prevUPCs,
                    ...Array(matchingOrders.length).fill(scannedUPC),
                ]);

                setCurrentUPCInput("");
                setErrorMatchingUpcToOrder(false);
            } else {
                setErrorMatchingUpcToOrder(true);
            }
        }
    };

    const handleRemoveUpc = (scannedUPC: string) => {
        setScannedUpcs((prevUPCs) => {
            const indexToRemove = prevUPCs.indexOf(scannedUPC);
            if (indexToRemove === -1) return prevUPCs; // UPC not found

            // Create a new array without the removed UPC
            const updatedUPCs = [...prevUPCs];
            updatedUPCs.splice(indexToRemove, 1);

            // Remove one matching verifiedOrderId if it exists
            let removedOrderId = null;
            setVerifiedOrderIds((prevIds) => {
                for (const order of outboundOrders) {
                    if (
                        order.upc === scannedUPC &&
                        prevIds.includes(order.order_id)
                    ) {
                        removedOrderId = order.order_id;
                        return prevIds.filter((id) => id !== order.order_id);
                    }
                }
                return prevIds; // No match found
            });

            // Optionally log the removed order ID
            // if (removedOrderId) {
            //     console.log(`Removed order ID: ${removedOrderId}`);
            // }

            return updatedUPCs;
        });
    };

    const canMarkShipped: boolean =
        scannedUPCs.length === outboundOrders.length &&
        !errorMatchingUpcToOrder;

    const [refetchSearchedData, setRefetchSearchedData] = useState(false);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    const isShipmentShipped =
        shipment?.status === "shipped" || shipment?.admin_status === "shipped";

    return (
        <Dialog
            open={openOutboundOrders}
            onClose={() => setOpenOutboundOrders(false)}
            className="relative z-20 overflow-visible"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible overflow-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen overflow-visible">
                <div className="h-[90%] flex min-h-full items-end overflow-visible justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenOutboundOrders(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            {!editingShipment ? (
                                <>
                                    Shipment:{" "}
                                    {shipment?.name !== ""
                                        ? shipment?.name
                                        : shipment?.shipment_id}
                                </>
                            ) : (
                                <input
                                    value={editedName}
                                    onChange={(e) =>
                                        setEditedName(e.target.value)
                                    }
                                    className="block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                />
                            )}
                            <div className="text-sm text-gray-600 font-normal flex flex-row items-center divide-x space-x-2 pt-1">
                                <span>{shipment?.full_name}</span>
                                <span className="pl-2">
                                    {shipment?.user_id}
                                </span>
                                <span className="pl-2">
                                    {shipment?.shipment_id}
                                </span>
                            </div>
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4"></div>

                        <div className="flex-1 mb-4 overflow-y-auto">
                            {isShipmentShipped && (
                                <dl className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pb-4 gap-x-10">
                                    <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm inline-flex items-center gap-x-2 font-medium leading-6 text-gray-900">
                                            Date
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                            {!editingShipment ? (
                                                <>
                                                    {formatDateFromDb(
                                                        shipment?.date || ""
                                                    )}
                                                </>
                                            ) : (
                                                <div className="w-44">
                                                    <SingleCalendar
                                                        selectedDate={
                                                            editedDate
                                                        }
                                                        setSelectedDate={
                                                            setEditedDate
                                                        }
                                                        label={null}
                                                    />
                                                </div>
                                            )}
                                        </dd>
                                    </div>
                                    <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            Notes
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                            {!editingShipment ? (
                                                <>{shipment?.notes}</>
                                            ) : (
                                                <input
                                                    value={editedNotes}
                                                    onChange={(e) =>
                                                        setEditedNotes(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="block w-44 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                />
                                            )}
                                        </dd>
                                    </div>
                                    {editingShipment ||
                                    (shipment?.status === "requested" &&
                                        shipment?.admin_status !==
                                            "shipped") ? (
                                        <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                                Shipping supplies
                                            </dt>
                                            <>
                                                <div className="flex flex-col items-start space-x-0 space-y-2">
                                                    <BoxesPopup
                                                        boxes={boxes}
                                                        selectedBoxes={
                                                            selectedBoxes
                                                        }
                                                        setSelectedBoxes={
                                                            setSelectedBoxes
                                                        }
                                                    />
                                                </div>
                                            </>
                                        </div>
                                    ) : (
                                        <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                                Shipping supplies
                                            </dt>
                                            {shipment?.boxes.map((box) => (
                                                <dd className="mt-0.5 text-sm leading-6 text-gray-700 sm:mt-0.5 flex flex-row items-center justify-between">
                                                    <div>
                                                        {box.name}
                                                        {": "}
                                                        {box.quantity}
                                                    </div>
                                                </dd>
                                            ))}
                                        </div>
                                    )}
                                    <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            Extra Services
                                        </dt>
                                        {!editingShipment ? (
                                            <>
                                                {selectedServices &&
                                                    selectedServices.map(
                                                        (service) => (
                                                            <div className="flex flex-row items-center space-x-4">
                                                                <span className="text-sm text-gray-800">
                                                                    {service.label ===
                                                                    "Returns" ? (
                                                                        <>
                                                                            {
                                                                                service.label
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                service.label
                                                                            }
                                                                            :
                                                                        </>
                                                                    )}
                                                                </span>
                                                                {service.label ===
                                                                "Returns" ? (
                                                                    <></>
                                                                ) : (
                                                                    <span className="text-sm text-gray-800">
                                                                        {
                                                                            service.quantity
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </>
                                        ) : (
                                            <>
                                                {editingShipment ||
                                                (shipment?.status ===
                                                    "requested" &&
                                                    shipment?.admin_status !==
                                                        "shipped") ? (
                                                    <ServicesPopup
                                                        selectedServices={
                                                            selectedServices
                                                        }
                                                        setSelectedServices={
                                                            setSelectedServices
                                                        }
                                                        widthFull={true}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {shipment?.status === "requested" && (
                                        <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                                Attached labels
                                            </dt>
                                            <dd className="mt-1 text-sm leading-6 sm:mt-1 text-accent cursor-pointer">
                                                <div className="flex flex-row space-x-4">
                                                    {printLoading ? (
                                                        <LoadingWheel size="small" />
                                                    ) : (
                                                        <span
                                                            onClick={() =>
                                                                fetchAndPrintLabel(
                                                                    showNotification,
                                                                    shipment?.shipment_id ||
                                                                        "",
                                                                    setPrintLoading
                                                                )
                                                            }
                                                        >
                                                            Print
                                                        </span>
                                                    )}
                                                    {downloadLoading ? (
                                                        <LoadingWheel size="small" />
                                                    ) : (
                                                        <span
                                                            onClick={() =>
                                                                handleDownload()
                                                            }
                                                        >
                                                            Download
                                                        </span>
                                                    )}
                                                    {viewingLabelLoading ? (
                                                        <LoadingWheel size="small" />
                                                    ) : (
                                                        <span
                                                            onClick={() =>
                                                                handleViewShipmentPdf()
                                                            }
                                                        >
                                                            View
                                                        </span>
                                                    )}
                                                </div>
                                            </dd>
                                        </div>
                                    )}
                                </dl>
                            )}
                            <div className="flex flex-row items-center justify-between pb-1">
                                <span className="font-semibold text-base text-gray-900">
                                    Shipment contents
                                </span>
                            </div>
                            <AdminSearchBar
                                setData={setOutboundOrders}
                                setLoading={setLoading}
                                handleRefetch={handleOrdersRefetch}
                                page="outbound"
                                sub_page={shipment?.shipment_id || ""}
                                user_id={user_id}
                                keywords={keywords}
                                setKeywords={setKeywords}
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                                refetchSearchedData={refetchSearchedData}
                            />
                            {!loading ? (
                                <>
                                    <ClientOutboundTable
                                        outboundOrders={outboundOrders}
                                        results={results}
                                        offset={offset}
                                        setOffset={setOffset}
                                        verifiedOrderIds={verifiedOrderIds}
                                    />
                                    <div className="pb-1"></div>
                                    {shipment?.status === "requested" &&
                                        shipment.admin_status !== "shipped" && (
                                            <div
                                                className={`${
                                                    scannedUPCs.length ===
                                                        outboundOrders.length &&
                                                    !errorMatchingUpcToOrder
                                                        ? "border-green-200 bg-green-100"
                                                        : !errorMatchingUpcToOrder
                                                        ? "border-gray-200 bg-gray-100"
                                                        : "border-red-200 bg-red-100"
                                                } border py-3 flex flex-row items-center justify-between px-2 rounded-md`}
                                            >
                                                <div>
                                                    {scannedUPCs.length ===
                                                        outboundOrders.length &&
                                                    !errorMatchingUpcToOrder ? (
                                                        <h1 className="text-green-700 font-semibold text-sm">
                                                            Shipment Ready to Be
                                                            Shipped!
                                                        </h1>
                                                    ) : !errorMatchingUpcToOrder ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                currentUPCInput
                                                            }
                                                            onChange={
                                                                handleUpcInputChange
                                                            }
                                                            onKeyDown={
                                                                handleUpcKeyDown
                                                            }
                                                            className="block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                            placeholder="Press here and scan the UPC's"
                                                        />
                                                    ) : (
                                                        <div className="flex flex-row items-center space-x-2">
                                                            <h1 className="text-red-600 font-medium text-sm">
                                                                UPC doesn't
                                                                match (
                                                                {
                                                                    currentUPCInput
                                                                }
                                                                ):
                                                            </h1>
                                                            <ActionButton
                                                                label="Confirm UPC Anyways"
                                                                handleClick={() =>
                                                                    confirmUpcMismatch()
                                                                }
                                                            />
                                                            <DeleteButton
                                                                label="Delete UPC"
                                                                handleClick={() => {
                                                                    setCurrentUPCInput(
                                                                        ""
                                                                    );
                                                                    setErrorMatchingUpcToOrder(
                                                                        false
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {scannedUPCs.length > 0 && (
                                                        <div className="flex flex-wrap flex-row items-center text-sm text-gray-700 divide-x-2 gap-x-2 pt-2">
                                                            <span>
                                                                Scanned UPCs:
                                                            </span>
                                                            {scannedUPCs.map(
                                                                (item) => (
                                                                    <div className="pl-2 flex flex-row items-center space-x-2">
                                                                        <p className="">
                                                                            {
                                                                                item
                                                                            }
                                                                        </p>
                                                                        <XCircleIcon
                                                                            onClick={() =>
                                                                                handleRemoveUpc(
                                                                                    item
                                                                                )
                                                                            }
                                                                            className="text-red-500 h-4 w-4 hover:text-red-400 cursor-pointer"
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                    <div className="flex flex-wrap flex-row items-center text-sm text-gray-700 divide-x-2 gap-x-2 pt-2">
                                                        <span>
                                                            Unscanned UPCs:
                                                        </span>
                                                        {outboundOrders
                                                            .map((o) => o.upc)
                                                            .filter(
                                                                (upc) =>
                                                                    !scannedUPCs.includes(
                                                                        upc ||
                                                                            ""
                                                                    )
                                                            )
                                                            .map((item) => (
                                                                <p className="pl-2">
                                                                    {item}
                                                                </p>
                                                            ))}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row items-center text-accent font-semibold text-sm">
                                                    {scannedUPCs.length}/
                                                    {outboundOrders.length} UPCs
                                                    Scanned
                                                </div>
                                            </div>
                                        )}
                                    {canMarkShipped && (
                                        <div className="grid grid-cols-3 pt-4">
                                            <div className="sm:col-span-1 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Attached labels
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 sm:mt-1 text-accent cursor-pointer">
                                                    <div className="flex flex-row space-x-4">
                                                        {printLoading ? (
                                                            <LoadingWheel size="small" />
                                                        ) : (
                                                            <span
                                                                onClick={() =>
                                                                    fetchAndPrintLabel(
                                                                        showNotification,
                                                                        shipment?.shipment_id ||
                                                                            "",
                                                                        setPrintLoading
                                                                    )
                                                                }
                                                            >
                                                                Print
                                                            </span>
                                                        )}
                                                        {downloadLoading ? (
                                                            <LoadingWheel size="small" />
                                                        ) : (
                                                            <span
                                                                onClick={() =>
                                                                    handleDownload()
                                                                }
                                                            >
                                                                Download
                                                            </span>
                                                        )}
                                                        {viewingLabelLoading ? (
                                                            <LoadingWheel size="small" />
                                                        ) : (
                                                            <span
                                                                onClick={() =>
                                                                    handleViewShipmentPdf()
                                                                }
                                                            >
                                                                View
                                                            </span>
                                                        )}
                                                    </div>
                                                </dd>
                                            </div>
                                            <BoxesPopup
                                                boxes={boxes}
                                                selectedBoxes={selectedBoxes}
                                                setSelectedBoxes={
                                                    setSelectedBoxes
                                                }
                                            />
                                            <ServicesPopup
                                                selectedServices={
                                                    selectedServices
                                                }
                                                setSelectedServices={
                                                    setSelectedServices
                                                }
                                            />
                                        </div>
                                    )}
                                    {canMarkShipped || isShipmentShipped ? (
                                        <>
                                            <div className="py-2">
                                                <div className="h-px w-full bg-gray-300"></div>
                                            </div>

                                            <input
                                                type="text"
                                                id="tracking"
                                                value={currentInput}
                                                onChange={handleInputChange}
                                                onKeyDown={handleKeyDown}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                placeholder="Press here and start scanning."
                                            />

                                            {shipmentTrackings &&
                                            shipmentTrackings.length > 0 ? (
                                                <div className="grid grid-cols-2 gap-x-5 gap-y-3 pt-3">
                                                    {shipmentTrackings.map(
                                                        (barcode, index) => (
                                                            <div className="flex flex-row w-full justify-between items-center">
                                                                <div className="flex flex-col">
                                                                    <div className="flex flex-row items-center">
                                                                        <a
                                                                            href={
                                                                                barcode.tracking_url
                                                                            }
                                                                            target="_blank"
                                                                            className="text-sm text-accent"
                                                                        >
                                                                            {
                                                                                barcode.tracking
                                                                            }
                                                                        </a>
                                                                        <CopyButton
                                                                            textToCopy={
                                                                                barcode.tracking
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <span className="text-sm text-gray-600">
                                                                        {
                                                                            barcode.carrier
                                                                        }
                                                                    </span>
                                                                </div>
                                                                {deletingTrackings.includes(
                                                                    barcode.tracking_id
                                                                ) ? (
                                                                    <div className="w-5 h-5">
                                                                        <LoadingWheel />{" "}
                                                                    </div>
                                                                ) : (
                                                                    <TrashIcon
                                                                        onClick={() =>
                                                                            handleDeleteTracking(
                                                                                barcode.tracking_id
                                                                            )
                                                                        }
                                                                        className="w-5 h-5 text-red-500"
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ) : (
                                                <span className="text-sm text-gray-600">
                                                    No trackings on this
                                                    shipment
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <div className="flex flex-grow items-center pt-44 justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex justify-between">
                            <div className="flex flex-row items-center space-x-4">
                                <button
                                    onClick={() => handleDeleteShipment()}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto items-center"
                                >
                                    {deleteLoading ? (
                                        <span className="pr-2 items-center flex flex-row justify-center">
                                            <LoadingWheel
                                                size="small"
                                                color="white"
                                            />
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    Delete
                                </button>
                                {editingShipment ? (
                                    <button
                                        onClick={() => handleUpdate()}
                                        type="button"
                                        className="flex flex-row w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:mt-0 sm:w-auto items-center"
                                    >
                                        {editLoading ? (
                                            <span className="pr-2 items-center flex flex-row justify-center">
                                                <LoadingWheel
                                                    color="white"
                                                    size="small"
                                                />
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                        Save
                                    </button>
                                ) : (
                                    <>
                                        {shipment?.status === "shipped" ||
                                        shipment?.admin_status === "shipped" ? (
                                            <button
                                                onClick={() =>
                                                    setEditingShipment(
                                                        !editingShipment
                                                    )
                                                }
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto items-center"
                                            >
                                                {!editingShipment
                                                    ? "Edit"
                                                    : "Cancel editing"}
                                            </button>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="sm:space-x-3 flex flex-row">
                                {shipment?.status === "requested" ? (
                                    <>
                                        {!shipment?.admin_status ||
                                        shipment?.admin_status === "pending" ? (
                                            <ActionButton
                                                label="Mark shipped"
                                                handleClick={() =>
                                                    handleToggleStatus()
                                                }
                                                loading={statusLoading}
                                                viewDisabled={
                                                    !canMarkShipped ||
                                                    selectedBoxes.length < 1 ||
                                                    statusLoading
                                                }
                                                height={8}
                                            />
                                        ) : (
                                            <button
                                                onClick={() =>
                                                    handleToggleStatus()
                                                }
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto items-center"
                                            >
                                                {statusLoading ? (
                                                    <span className="pr-2 items-center flex flex-row justify-center">
                                                        <LoadingWheel size="small" />
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                                Mark unshipped
                                            </button>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
            <LabelModal
                selectedViewingLabel={selectedViewingLabel}
                openLabelModal={openLabelModal}
                setOpenLabelModal={setOpenLabelModal}
                selectedPages={[]}
                pdfLabel={true}
            />
        </Dialog>
    );
}
