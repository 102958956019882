/** @format */

import { DocumentPlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, createElement, FC } from "react";
import LoadingWheel from "./loadingWheel";
import { IconType } from "react-icons";

interface InputFieldProps {
    label: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    optional?: boolean;
    extraText?: string;
    selectedFiles?: File[];
    setSelectedFiles?: React.Dispatch<React.SetStateAction<File[]>>;
    disabled?: boolean;
    loading?: boolean;
    customIcon?:
        | IconType
        | React.ForwardRefExoticComponent<
              Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
                  title?: string;
                  titleId?: string;
              } & React.RefAttributes<SVGSVGElement>
          >;
    handleIconClick?: any;
    handleFocus?: any;
    handleBlur?: any;
    handleKeyDown?: any;
    labelIcon?: any;
    handleLabelIconClick?: any;
}

export const InputField: FC<InputFieldProps> = ({
    label,
    value,
    onChange,
    placeholder,
    optional,
    extraText,
    disabled = false,
    loading = false,
    customIcon,
    handleIconClick,
    handleFocus,
    handleBlur,
    handleKeyDown,
    labelIcon,
    handleLabelIconClick,
}) => (
    <div className="text-left w-full space-y-0.5">
        <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-2">
                <span className="font-medium text-sm">{label}</span>

                {labelIcon && (
                    <div className="" onClick={() => handleLabelIconClick()}>
                        {labelIcon &&
                            createElement(labelIcon, {
                                className:
                                    "w-4 h-4 text-accent hover:text-accenthighlight cursor-pointer",
                            })}
                    </div>
                )}
            </div>
            {optional ? (
                <span className="text-sm text-gray-500">Optional</span>
            ) : (
                <></>
            )}
            {extraText ? (
                <span className="text-xs text-gray-500 text-right whitespace-nowrap overflow-hidden text-ellipsis max-w-36">
                    {extraText}
                </span>
            ) : (
                <></>
            )}
        </div>
        <div className="w-full relative">
            <input
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                className={`disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6`}
                onFocus={handleFocus ? () => handleFocus() : () => {}}
                onBlur={handleBlur ? () => handleBlur() : () => {}}
                onKeyDown={handleKeyDown ? handleKeyDown : () => {}}
            />
            {loading && (
                <div className="absolute top-1.5 right-2">
                    <LoadingWheel />
                </div>
            )}
            {customIcon && (
                <div
                    className="absolute top-2 right-2 hover:text-accent cursor-pointer"
                    onClick={() => handleIconClick()}
                >
                    {customIcon &&
                        createElement(customIcon, { className: "w-5 h-5" })}
                </div>
            )}
        </div>
    </div>
);

export const InputNumberField: FC<InputFieldProps> = ({
    label,
    value,
    onChange,
    placeholder,
    optional,
    disabled,
    extraText,
}) => (
    <div className="text-left w-full space-y-0.5">
        <div className="flex flex-row items-center justify-between">
            <span className="font-medium text-sm">{label}</span>
            {optional ? (
                <span className="text-sm text-gray-500">Optional</span>
            ) : (
                <></>
            )}
            {extraText ? (
                <span className="text-xs text-gray-500 text-right whitespace-nowrap overflow-hidden text-ellipsis max-w-36">
                    {extraText}
                </span>
            ) : (
                <></>
            )}
        </div>
        <input
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            type="number"
            disabled={disabled}
            className="disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
            onWheel={(e) => {
                const target = e.target as HTMLInputElement;
                target.blur();
                setTimeout(() => target.focus(), 0);
            }}
        />
    </div>
);

export function InputFileField({
    label,
    value,
    onChange,
    placeholder,
    optional,
    extraText,
    selectedFiles,
    setSelectedFiles,
}: InputFieldProps) {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            setSelectedFiles &&
                setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
        }
    };

    const handleFileRemove = (index: number) => {
        setSelectedFiles &&
            setSelectedFiles((prevFiles) =>
                prevFiles.filter((_, i) => i !== index)
            );
    };

    return (
        <div className="text-left w-full space-y-0.5">
            <div className="flex flex-row items-center justify-between">
                <span className="font-medium text-sm">{label}</span>
                {optional ? (
                    <span className="text-sm text-gray-500">Optional</span>
                ) : (
                    <></>
                )}
                {extraText ? (
                    <span className="text-xs text-gray-500 text-right whitespace-nowrap overflow-hidden text-ellipsis max-w-36">
                        {extraText}
                    </span>
                ) : (
                    <></>
                )}
            </div>

            <div>
                <label htmlFor="file-upload">
                    <div className="gap-x-2 pl-3 flex flex-row text-accent items-center cursor-pointer w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6">
                        <DocumentPlusIcon className="w-5 h-5 " />
                        Upload a file
                    </div>
                    <input
                        onChange={handleFileChange}
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        placeholder={placeholder}
                        className="sr-only block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                    />
                </label>
            </div>
            <div className="overflow-x-scroll">
                {selectedFiles &&
                    selectedFiles.map((file, index) => (
                        <div className="text-xs text-gray-700 flex flex-row items-center justify-between">
                            <p>{file.name}</p>
                            <XMarkIcon
                                onClick={() => handleFileRemove(index)}
                                className="w-3 h-3 shrink-0 text-red-500 cursor-pointer hover:text-red-400"
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
}

export function InputTextField({
    label,
    value,
    onChange,
}: {
    label: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}) {
    return (
        <div className="text-left w-full space-y-0.5">
            <div className="flex flex-row items-center justify-between">
                <span className="font-medium text-sm">{label}</span>
            </div>
            <div className="w-full relative">
                <textarea
                    id="comment"
                    name="comment"
                    rows={2}
                    className="block w-full rounded-md px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent sm:text-sm/6"
                    defaultValue={""}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}
