/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../components/loadingWheel";
import { useEffect, useState } from "react";
import {
    changePaymentMethod,
    resendPastInvoice,
    toggleInvoicePaymentStatus,
} from "../../api/billingApi";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { InputField } from "../../../../components/inputField";
import { Invoice } from "../../../../models/invoices";
import CopyButton from "../../../../components/copyButton";
import { capitalizeFirstLetter } from "../../../../utils/strings/string";
import { ActionButton, SecondaryButton } from "../../../../components/buttons";
import { paymentMethods } from "./closeOpenInvoiceModal";

type PaymentStatus = "paid" | "unpaid";

function EditPastInvoiceModal({
    openEditPastInvoiceModal,
    setOpenEditPastInvoiceModal,
    selectedPastInvoice,
    setSelectedPastInvoice,
    handleRefetch,
}: {
    openEditPastInvoiceModal: boolean;
    setOpenEditPastInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPastInvoice: Invoice | null;
    setSelectedPastInvoice: React.Dispatch<
        React.SetStateAction<Invoice | null>
    >;
    handleRefetch: () => void;
}) {
    const { user_id } = useParams();
    const [loading, setLoading] = useState(false);

    const { showNotification } = useNotification();

    const [invoicePaymentUrl, setInvoicePaymentUrl] = useState("");

    useEffect(() => {
        if (openEditPastInvoiceModal) {
            setInvoicePaymentUrl(selectedPastInvoice?.invoice_url || "");
            setPaymentMethod(selectedPastInvoice?.payment_method || "");
            setOpenChangePaymentMethod(false);
        } else {
            setSelectedPastInvoice(null);
        }
    }, [openEditPastInvoiceModal]);

    const handleResend = async () => {
        setLoading(true);
        const data = await resendPastInvoice(selectedPastInvoice?.invoice_id);
        if (data.status === "success") {
            setOpenEditPastInvoiceModal(false);
            showNotification("Resent stripe invoice.", "", "success");
        } else {
            showNotification("Failed to resend invoice", data.message, "error");
        }
        setLoading(false);
    };

    const [paidLoading, setPaidLoading] = useState(false);
    const handleTogglePaidStatus = async () => {
        setPaidLoading(true);
        const data = await toggleInvoicePaymentStatus(
            selectedPastInvoice?.invoice_id || ""
        );
        if (data.status === "success") {
            setOpenEditPastInvoiceModal(false);
            handleRefetch();
            showNotification(
                "Edited payment status for invoice.",
                "",
                "success"
            );
        } else {
            showNotification(
                "Failed to edit payment status",
                data.message,
                "error"
            );
        }
        setPaidLoading(false);
    };

    const [openChangePaymentMethod, setOpenChangePaymentMethod] =
        useState(false);
    const [paymentMethod, setPaymentMethod] = useState(
        selectedPastInvoice?.payment_method || ""
    );
    const [customPaymentMethod, setCustomPaymentMethod] = useState("");

    const [changeLoading, setChangeLoading] = useState(false);
    const handleChangePaymentMethod = async () => {
        setChangeLoading(true);
        const data = await changePaymentMethod(
            selectedPastInvoice?.invoice_id || "",
            paymentMethod !== "other" ? paymentMethod : customPaymentMethod
        );
        if (data.status === "success") {
            setOpenEditPastInvoiceModal(false);
            handleRefetch();
            showNotification(
                "Changed the payment method for invoice.",
                "",
                "success"
            );
        } else {
            showNotification(
                "Failed to edit payment method",
                data.message,
                "error"
            );
        }
        setChangeLoading(false);
    };

    return (
        <Dialog
            open={openEditPastInvoiceModal}
            onClose={() => setOpenEditPastInvoiceModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-3/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenEditPastInvoiceModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Closed invoice period
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <div className="flex flex-row items-center">
                                <InputField
                                    label="Payment URL"
                                    value={invoicePaymentUrl}
                                    onChange={(e) =>
                                        setInvoicePaymentUrl(e.target.value)
                                    }
                                    disabled={true}
                                />
                                <div className="pt-6">
                                    <CopyButton
                                        textToCopy={invoicePaymentUrl}
                                    />
                                </div>
                            </div>
                            <div>
                                <InputField
                                    label="Payment Method"
                                    value={capitalizeFirstLetter(
                                        selectedPastInvoice?.payment_method ||
                                            ""
                                    )}
                                    onChange={(e) =>
                                        setInvoicePaymentUrl(e.target.value)
                                    }
                                    placeholder=""
                                    disabled={true}
                                />
                                <p
                                    onClick={() =>
                                        setOpenChangePaymentMethod(
                                            (prev) => !prev
                                        )
                                    }
                                    className="cursor-pointer text-accent font-medium text-xs hover:text-accenthighlight pt-1"
                                >
                                    Change payment method
                                </p>
                                {openChangePaymentMethod && (
                                    <div>
                                        <span className="isolate inline-flex rounded-md shadow-sm pt-4">
                                            {paymentMethods.map(
                                                (item, index) => (
                                                    <button
                                                        onClick={() =>
                                                            setPaymentMethod(
                                                                item
                                                            )
                                                        }
                                                        type="button"
                                                        className={`${
                                                            paymentMethod ===
                                                            item
                                                                ? "bg-accent text-white ring-1 ring-inset ring-accent"
                                                                : "bg-white text-gray-900 hover:bg-gray-50 ring-1 ring-inset ring-gray-300"
                                                        } ${
                                                            index === 0
                                                                ? "rounded-l-md"
                                                                : ""
                                                        } ${
                                                            index ===
                                                            paymentMethods.length -
                                                                1
                                                                ? "rounded-r-md"
                                                                : ""
                                                        } relative inline-flex w-24 justify-center items-center px-3 py-2 text-sm font-semibold focus:z-10`}
                                                    >
                                                        {capitalizeFirstLetter(
                                                            item
                                                        )}
                                                    </button>
                                                )
                                            )}
                                        </span>
                                        {paymentMethod === "other" && (
                                            <div className="w-72">
                                                <InputField
                                                    label=""
                                                    value={customPaymentMethod}
                                                    onChange={(e) =>
                                                        setCustomPaymentMethod(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Custom payment method"
                                                />
                                            </div>
                                        )}
                                        <div className="pt-4">
                                            <ActionButton
                                                label="Update payment method"
                                                handleClick={() =>
                                                    handleChangePaymentMethod()
                                                }
                                                loading={changeLoading}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse gap-x-4 justify-between">
                            <div className="flex flex-row items-center space-x-4">
                                {selectedPastInvoice?.payment_method !==
                                    "stripe" && (
                                    <ActionButton
                                        label={
                                            selectedPastInvoice?.payment_status ===
                                            "paid"
                                                ? "Mark unpaid"
                                                : "Mark paid"
                                        }
                                        handleClick={() =>
                                            handleTogglePaidStatus()
                                        }
                                        height={9}
                                        loading={paidLoading}
                                    />
                                )}
                                {selectedPastInvoice?.payment_status !==
                                    "paid" &&
                                    selectedPastInvoice?.payment_method ===
                                        "stripe" && (
                                        <ActionButton
                                            label="Resend invoice"
                                            handleClick={() => handleResend()}
                                            loading={loading}
                                            height={9}
                                        />
                                    )}
                            </div>
                            <SecondaryButton
                                label="Cancel"
                                handleClick={() =>
                                    setOpenEditPastInvoiceModal(false)
                                }
                                height={9}
                            />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default EditPastInvoiceModal;
