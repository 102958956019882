/** @format */

// /** @format */

// import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";
// import { ScheduleEvent } from "./page";
// import { useState } from "react";
// import EditScheduleEventModal from "./editScheduleEventModal";

// dayjs.extend(customParseFormat);

// // const calculateGridRow = (start: string, end: string) => {
// //     const startTime = dayjs(start, "h:mm A");
// //     const endTime = dayjs(end, "h:mm A");

// //     // Ensure both times are valid
// //     if (!startTime.isValid() || !endTime.isValid()) {
// //         console.error(`Invalid time format: start=${start}, end=${end}`);
// //         return { startRow: 0, span: 0 };
// //     }

// //     const startRow =
// //         startTime.hour() * 12 + Math.floor(startTime.minute() / 5) + 2;
// //     const endRow = endTime.hour() * 12 + Math.floor(endTime.minute() / 5) + 2;

// //     const span = endRow - startRow;

// //     return { startRow, span };
// // };

// const calculateGridRow = (start: string, end: string) => {
//     const startTime = dayjs(start, "h:mm A");
//     let endTime = end ? dayjs(end, "h:mm A") : startTime.add(2, "hour");

//     // Ensure both times are valid
//     if (!startTime.isValid() || !endTime.isValid()) {
//         console.error(`Invalid time format: start=${start}, end=${end}`);
//         return { startRow: 0, span: 0 };
//     }

//     // Calculate the start and end rows with a 6:00 AM offset
//     const startRow =
//         (startTime.hour() - 6) * 12 + Math.floor(startTime.minute() / 5) + 2;
//     const endRow =
//         (endTime.hour() - 6) * 12 + Math.floor(endTime.minute() / 5) + 2;

//     const span = endRow - startRow;

//     return { startRow, span };
// };

// export default function CalendarEvents({
//     scheduleEvents,
//     handleRefetch,
// }: {
//     scheduleEvents: ScheduleEvent[];
//     handleRefetch: () => void;
// }) {
//     const [openEditScheduleEventModal, setOpenEditScheduleEventModal] =
//         useState(false);

//     const [selectedEvent, setSelectedEvent] = useState<ScheduleEvent | null>(
//         null
//     );

//     return (
//         // <ol
//         //     className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
//         //     style={{
//         //         gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
//         //     }}
//         // >
//         //     {scheduleEvents.map((event) => {
//         //         const { startRow, span } = calculateGridRow(
//         //             event.start_time,
//         //             event.end_time
//         //         );

//         //         console.log(`${startRow} / span ${span}`);
//         //         console.log(event.start_time);
//         //         console.log(dayjs(event.start_time, "h:mm A").isValid());

//         //         return (
//         //             <li
//         //                 key={event.event_id}
//         //                 className={`relative mt-px flex sm:col-start-${event.column}`}
//         //                 style={{
//         //                     gridRow: `${startRow} / span ${span}`,
//         //                 }}
//         //             >
//         //                 <a className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-accentbg p-2 text-xs leading-5 hover:bg-accentdim">
//         //                     <p className="order-1 font-semibold text-accent">
//         //                         {event.description}
//         //                     </p>
//         //                     <p className="text-accent group-hover:text-accent">
//         //                         <time>
//         //                             {event.start_time} - {event.end_time}
//         //                         </time>
//         //                     </p>
//         //                 </a>
//         //             </li>
//         //         );
//         //     })}
//         // </ol>
//         <ol
//             className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
//             style={{
//                 gridTemplateRows: "1.75rem repeat(216, minmax(0, 1fr)) auto", // 216 rows for 6:00 AM to 12:00 AM
//             }}
//         >
//             {scheduleEvents.map((event) => {
//                 const { startRow, span } = calculateGridRow(
//                     event.start_time,
//                     event.end_time
//                 );
//                 console.log(event.column_number);
//                 return (
//                     <li
//                         key={event.event_id}
//                         className={`relative mt-px flex sm:col-start-${event.column_number}`}
//                         style={{
//                             gridRow: `${startRow} / span ${span}`,
//                         }}
//                     >
//                         <a
//                             onClick={() => {
//                                 setOpenEditScheduleEventModal(true);
//                                 setSelectedEvent(event);
//                             }}
//                             className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-accentbg p-2 text-xs leading-5 hover:bg-accentdim"
//                         >
//                             <p className="order-1 font-semibold text-accent">
//                                 {event.description}
//                             </p>
//                             <p className="text-accent group-hover:text-accent">
//                                 <time>
//                                     {event.start_time} -{" "}
//                                     {event.end_time
//                                         ? event.end_time
//                                         : "No end time"}
//                                 </time>
//                             </p>
//                         </a>
//                     </li>
//                 );
//             })}

//             <EditScheduleEventModal
//                 openEditScheduleEventModal={openEditScheduleEventModal}
//                 setOpenEditScheduleEventModal={setOpenEditScheduleEventModal}
//                 event={selectedEvent}
//                 handleRefetch={handleRefetch}
//             />
//         </ol>
//     );
// }
// const calculateGridRowNoTimes = (
//     columnNumber: number,
//     lastRowPerColumn: any
// ) => {
//     const span = 48;

//     const lastStartRow = lastRowPerColumn[columnNumber] || 2;

//     const startRow = lastStartRow;

//     lastRowPerColumn[columnNumber] = startRow + span;

//     return { startRow, span };
// };

// export function CalendarEventsNoTimes({
//     scheduleEvents,
//     handleRefetch,
// }: {
//     scheduleEvents: ScheduleEvent[];
//     handleRefetch: () => void;
// }) {
//     const lastRowPerColumn = {};

//     const [openEditScheduleEventModal, setOpenEditScheduleEventModal] =
//         useState(false);

//     const [selectedEvent, setSelectedEvent] = useState<ScheduleEvent | null>(
//         null
//     );

//     return (
//         <ol
//             className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
//             style={{
//                 gridTemplateRows: "1.75rem repeat(216, minmax(0, 1fr)) auto", // 216 rows for 6:00 AM to 12:00 AM
//             }}
//         >
//             {scheduleEvents.map((event) => {
//                 // Calculate the startRow and span for the current event
//                 const { startRow, span } = calculateGridRowNoTimes(
//                     event.column_number,
//                     lastRowPerColumn
//                 );

//                 return (
//                     <li
//                         key={event.event_id}
//                         className={`relative mt-px flex sm:col-start-${event.column_number}`}
//                         style={{
//                             gridRow: `${startRow} / span ${span}`,
//                         }}
//                     >
//                         <a
//                             onClick={() => {
//                                 setOpenEditScheduleEventModal(true);
//                                 setSelectedEvent(event);
//                             }}
//                             className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-accentbg p-2 text-xs leading-5 hover:bg-accentdim"
//                         >
//                             <p className="order-1 font-semibold text-accent">
//                                 {event.employee_name}
//                             </p>
//                             <p className="text-accent group-hover:text-accent">
//                                 <time>
//                                     {event.start_time} -{" "}
//                                     {event.end_time
//                                         ? event.end_time
//                                         : "No end time"}
//                                 </time>
//                             </p>
//                         </a>
//                     </li>
//                 );
//             })}

//             <EditScheduleEventModal
//                 openEditScheduleEventModal={openEditScheduleEventModal}
//                 setOpenEditScheduleEventModal={setOpenEditScheduleEventModal}
//                 event={selectedEvent}
//                 handleRefetch={handleRefetch}
//             />
//         </ol>
//     );
// }

/** @format */

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ScheduleEvent } from "./page";
import { useState } from "react";
import EditScheduleEventModal from "./editScheduleEventModal";

dayjs.extend(customParseFormat);

// const calculateGridRow = (start: string, end: string) => {
//     const startTime = dayjs(start, "h:mm A");
//     const endTime = dayjs(end, "h:mm A");

//     // Ensure both times are valid
//     if (!startTime.isValid() || !endTime.isValid()) {
//         console.error(`Invalid time format: start=${start}, end=${end}`);
//         return { startRow: 0, span: 0 };
//     }

//     const startRow =
//         startTime.hour() * 12 + Math.floor(startTime.minute() / 5) + 2;
//     const endRow = endTime.hour() * 12 + Math.floor(endTime.minute() / 5) + 2;

//     const span = endRow - startRow;

//     return { startRow, span };
// };

const calculateGridRow = (start: string, end: string) => {
    const startTime = dayjs(start, "h:mm A");
    let endTime = end ? dayjs(end, "h:mm A") : startTime.add(2, "hour");

    // Ensure both times are valid
    if (!startTime.isValid() || !endTime.isValid()) {
        console.error(`Invalid time format: start=${start}, end=${end}`);
        return { startRow: 0, span: 0 };
    }

    // Calculate the start and end rows with a 6:00 AM offset
    const startRow =
        (startTime.hour() - 6) * 12 + Math.floor(startTime.minute() / 5) + 2;
    const endRow =
        (endTime.hour() - 6) * 12 + Math.floor(endTime.minute() / 5) + 2;

    const span = endRow - startRow;

    return { startRow, span };
};

export default function CalendarEvents({
    scheduleEvents,
    handleRefetch,
}: {
    scheduleEvents: ScheduleEvent[];
    handleRefetch: () => void;
}) {
    const [openEditScheduleEventModal, setOpenEditScheduleEventModal] =
        useState(false);

    const [selectedEvent, setSelectedEvent] = useState<ScheduleEvent | null>(
        null
    );

    return (
        <ol
            className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 pr-8"
            style={{
                gridTemplateColumns: "repeat(7, minmax(120px, 1fr))",
                gridTemplateRows: "1.75rem repeat(216, minmax(0, 1fr)) auto",
            }}
        >
            {scheduleEvents.map((event) => {
                const { startRow, span } = calculateGridRow(
                    event.start_time,
                    event.end_time
                );
                console.log(event.column_number);
                return (
                    <li
                        key={event.event_id}
                        className={`relative mt-px flex col-start-${event.column_number}`}
                        style={{
                            gridRow: `${startRow} / span ${span}`,
                        }}
                    >
                        <a
                            onClick={() => {
                                setOpenEditScheduleEventModal(true);
                                setSelectedEvent(event);
                            }}
                            className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-accentbg p-2 text-xs leading-5 hover:bg-accentdim"
                        >
                            <p className="order-1 font-semibold text-accent">
                                {event.description}
                            </p>
                            <p className="text-accent group-hover:text-accent">
                                <time>
                                    {event.start_time} -{" "}
                                    {event.end_time
                                        ? event.end_time
                                        : "No end time"}
                                </time>
                            </p>
                        </a>
                    </li>
                );
            })}

            <EditScheduleEventModal
                openEditScheduleEventModal={openEditScheduleEventModal}
                setOpenEditScheduleEventModal={setOpenEditScheduleEventModal}
                event={selectedEvent}
                handleRefetch={handleRefetch}
            />
        </ol>
    );
}
const calculateGridRowNoTimes = (
    columnNumber: number,
    lastRowPerColumn: any
) => {
    const span = 48;

    const lastStartRow = lastRowPerColumn[columnNumber] || 2;

    const startRow = lastStartRow;

    lastRowPerColumn[columnNumber] = startRow + span;

    return { startRow, span };
};

export function CalendarEventsNoTimes({
    scheduleEvents,
    handleRefetch,
}: {
    scheduleEvents: ScheduleEvent[];
    handleRefetch: () => void;
}) {
    const lastRowPerColumn = {};

    const [openEditScheduleEventModal, setOpenEditScheduleEventModal] =
        useState(false);

    const [selectedEvent, setSelectedEvent] = useState<ScheduleEvent | null>(
        null
    );

    return (
        <ol
            className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 pr-8"
            style={{
                gridTemplateColumns: "repeat(7, minmax(120px, 1fr))",
                gridTemplateRows: "1.75rem repeat(216, minmax(0, 1fr)) auto",
            }}
        >
            {scheduleEvents.map((event) => {
                // Calculate the startRow and span for the current event
                const { startRow, span } = calculateGridRowNoTimes(
                    event.column_number,
                    lastRowPerColumn
                );

                return (
                    <li
                        key={event.event_id}
                        className={`relative mt-px flex col-start-${event.column_number}`}
                        style={{
                            gridRow: `${startRow} / span ${span}`,
                        }}
                    >
                        <a
                            onClick={() => {
                                setOpenEditScheduleEventModal(true);
                                setSelectedEvent(event);
                            }}
                            className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-accentbg p-2 text-xs leading-5 hover:bg-accentdim"
                        >
                            <p className="order-1 font-semibold text-accent">
                                {event.employee_name}
                            </p>
                            <p className="text-accent group-hover:text-accent">
                                <time>
                                    {event.start_time} -{" "}
                                    {event.end_time
                                        ? event.end_time
                                        : "No end time"}
                                </time>
                            </p>
                        </a>
                    </li>
                );
            })}

            <EditScheduleEventModal
                openEditScheduleEventModal={openEditScheduleEventModal}
                setOpenEditScheduleEventModal={setOpenEditScheduleEventModal}
                event={selectedEvent}
                handleRefetch={handleRefetch}
            />
        </ol>
    );
}
