import Cookies from "js-cookie";

export const apiRequest = async (
    endpoint: string,
    method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
    body?: any,
    isFormData: boolean = false,
    additionalData?: Record<string, any>,
    blobData: boolean = false,
    signal?: AbortSignal
) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1${endpoint}`;

    try {
        const headers: Record<string, string> = {
            Authorization: "Bearer " + Cookies.get("jwt"),
        };

        let requestBody: any;

        if (isFormData) {
            const formData = new FormData();

            if (body instanceof FileList || Array.isArray(body)) {
                Array.from(body).forEach((file, index) => {
                    formData.append(`file${index + 1}`, file);
                });
            } else if (body instanceof File) {
                formData.append("file", body);
            }

            if (additionalData) {
                Object.entries(additionalData).forEach(([key, value]) => {
                    if (Array.isArray(value) || typeof value === "boolean" || typeof value === "number") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                });
            }

            requestBody = formData;
        } else {
            // For non-FormData (JSON or plain text)
            headers["Content-Type"] = "application/json";
            requestBody = body ? JSON.stringify(body) : undefined;
        }

        const response = await fetch(url, {
            method,
            headers,
            body: requestBody,
            signal
        });

        let data;
        if (!blobData) {
            data = await response.json();
        } else {
            data = await response.blob();
        }
        return data;
        
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};

