/** @format */

import { useSearchParams } from "react-router-dom";
import { TrackingInfo } from "../models/trackings";
import { TrackingStatus } from "../models/order";
import { useEffect, useState } from "react";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import LoadingWheel, { SortLoading } from "../components/loadingWheel";
import { TrashIcon } from "@heroicons/react/24/outline";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import CopyButton, { SmallCopyButton } from "../components/copyButton";
import { LeadContact } from "../pages/admin/leads/contacts/page";
import { FormSubmission } from "../pages/admin/leads/form-submissions/page";
import { useNotification } from "../utils/notification/notificationContext";
import { deleteFormSubmission } from "../pages/admin/leads/api/leadsApi";
import { EditFormSubmissionModal } from "../pages/admin/leads/form-submissions/editFormSubmissionsModal";

export const initialFormSubmissionsColumnOrder = [
    {
        key: "select",
        label: "Select",
        isSortable: false,
        className: "sm:min-w-10 min-w-10",
    },
    {
        key: "status",
        label: "Status",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "created_at",
        label: "Date Created",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "name",
        label: "Name",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "company_name",
        label: "Company Name",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "email",
        label: "Email",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "phone",
        label: "Phone",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4 whitespace-nowrap",
    },
    {
        key: "units_a_month",
        label: "Units/month",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    {
        key: "business_details",
        label: "Business details",
        isSortable: false,
        className: "whitespace-nowrap pl-4",
    },
    {
        key: "type",
        label: "Type",
        isSortable: false,
        className: "pl-4 whitespace-nowrap",
    },
    {
        key: "notes",
        label: "Notes",
        isSortable: false,
        className: "pl-4 whitespace-nowrap",
    },
    {
        key: "delete",
        label: "",
        isSortable: false,
        className: "",
    },
];

function ContactedStatusTag({ status }: { status: string }) {
    if (status === "contacted") {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md">
                Contacted
            </span>
        );
    } else if (status === "converted") {
        return (
            <span className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md">
                Converted
            </span>
        );
    } else {
        return (
            <span className="text-gray-600 font-semibold bg-gray-100 py-1.5 px-2 rounded-md">
                Not Contacted
            </span>
        );
    }
}

interface FormSubmissionsTableProps {
    formSubmissions: FormSubmission[];
    results: Results;
    selectedFormSubmissions: FormSubmission[];
    setSelectedFormSubmissions: React.Dispatch<
        React.SetStateAction<FormSubmission[]>
    >;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder: Column[];
    hiddenHeaders: string[];
}

function FormSubmissionsTable({
    formSubmissions,
    results,
    selectedFormSubmissions,
    setSelectedFormSubmissions,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    hiddenHeaders,
}: FormSubmissionsTableProps) {
    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const displayedHeaders = columnOrder.filter(
        (header) => !hiddenHeaders.includes(header.key)
    );

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedFormSubmissions(formSubmissions);
        } else {
            setSelectedFormSubmissions([]);
        }
    };

    const handleCheckboxChange = (submission: FormSubmission) => {
        const submissionId = submission.form_submission_id;
        if (
            selectedFormSubmissions.some(
                (o) => o.form_submission_id === submissionId
            )
        ) {
            setSelectedFormSubmissions(
                selectedFormSubmissions.filter(
                    (o) => o.form_submission_id !== submissionId
                )
            );
        } else {
            setSelectedFormSubmissions([
                ...selectedFormSubmissions,
                submission,
            ]);
        }
    };

    const [deleteLoadings, setDeleteLoadings] = useState<string[]>([]);
    const handleDelete = async (form_submission_id: string) => {
        setDeleteLoadings((prev) => [...prev, form_submission_id]);
        const data = await deleteFormSubmission(form_submission_id);
        if (data.status === "success") {
            handleRefetch();
            showNotification("Deleted form submission.", "", "success");
        } else {
            showNotification("Failed to delete form submission.", "", "error");
        }
        setDeleteLoadings((prev) =>
            prev.filter((id) => id !== form_submission_id)
        );
    };

    const [openEditFormSubmissionModal, setOpenEditFormSubmissionModal] =
        useState(false);
    const [selectedSubmission, setSelectedSubmission] =
        useState<FormSubmission | null>(null);

    useEffect(() => {
        if (selectedSubmission) setOpenEditFormSubmissionModal(true);
    }, [selectedSubmission]);

    useEffect(() => {
        if (!openEditFormSubmissionModal) setSelectedSubmission(null);
    }, [openEditFormSubmissionModal]);

    const truncateText = (text: string, maxLength = 100) => {
        if (!text) return "";
        return text.length > maxLength
            ? text.slice(0, maxLength) + "..."
            : text;
    };

    return (
        <div className="flex flex-col pt-3">
            {formSubmissions && formSubmissions.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.key === "select" ? (
                                                    <input
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onChange={
                                                            handleSelectAll
                                                        }
                                                        checked={
                                                            selectedFormSubmissions &&
                                                            formSubmissions.length ===
                                                                selectedFormSubmissions.length &&
                                                            formSubmissions.length >
                                                                0
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        {header.label}
                                                        <ColumnSort
                                                            header={header}
                                                            sortConfig={
                                                                sortConfig
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {formSubmissions.map(
                                    (
                                        submission: FormSubmission,
                                        index: number
                                    ) => (
                                        <tr
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                            onClick={() =>
                                                setSelectedSubmission(
                                                    submission
                                                )
                                            }
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (header: Column) => (
                                                        <>
                                                            {header.key ===
                                                            "select" ? (
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                                        onChange={() =>
                                                                            handleCheckboxChange(
                                                                                submission
                                                                            )
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            e.stopPropagation()
                                                                        }
                                                                        checked={selectedFormSubmissions.some(
                                                                            (
                                                                                o
                                                                            ) =>
                                                                                o.form_submission_id ===
                                                                                submission.form_submission_id
                                                                        )}
                                                                    />
                                                                </td>
                                                            ) : header.key ===
                                                              "status" ? (
                                                                <td className="">
                                                                    <ContactedStatusTag
                                                                        status={
                                                                            submission.status
                                                                        }
                                                                    />
                                                                </td>
                                                            ) : header.key ===
                                                              "created_at" ? (
                                                                <td className="py-2.5 text-sm pl-4 whitespace-nowrap">
                                                                    {convertDateToEST(
                                                                        String(
                                                                            submission[
                                                                                header.key as keyof FormSubmission
                                                                            ]
                                                                        )
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "email" ? (
                                                                <td className="">
                                                                    <div className="flex flex-row items-center">
                                                                        <span>
                                                                            {
                                                                                submission.email
                                                                            }
                                                                        </span>
                                                                        <span
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                e.stopPropagation()
                                                                            }
                                                                        >
                                                                            <CopyButton
                                                                                textToCopy={
                                                                                    submission.email
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            ) : header.key ===
                                                              "business_details" ? (
                                                                <td className="pl-4">
                                                                    {truncateText(
                                                                        submission.business_details
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "delete" ? (
                                                                <td className="sticky right-0 bottom-0 text-right bg-white cursor-pointer">
                                                                    {!deleteLoadings.includes(
                                                                        submission.form_submission_id
                                                                    ) ? (
                                                                        <TrashIcon
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    submission.form_submission_id
                                                                                )
                                                                            }
                                                                            className="h-5 w-5 text-red-500 hover:text-red-400"
                                                                        />
                                                                    ) : (
                                                                        <LoadingWheel />
                                                                    )}
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className={`py-2.5 text-sm pl-4 ${
                                                                        [
                                                                            "business_details",
                                                                        ].includes(
                                                                            header.key
                                                                        )
                                                                            ? ""
                                                                            : "whitespace-nowrap"
                                                                    }`}
                                                                >
                                                                    {
                                                                        submission[
                                                                            header.key as keyof FormSubmission
                                                                        ]
                                                                    }
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/leads/contacts?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/leads/contacts?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}

            <EditFormSubmissionModal
                openEditFormSubmissionModal={openEditFormSubmissionModal}
                setOpenEditFormSubmissionModal={setOpenEditFormSubmissionModal}
                selectedSubmission={selectedSubmission}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default FormSubmissionsTable;
