/** @format */

import { ClientOrderCount } from "../../../../models/clients";

export const kpiTabs = (
    orderCount: ClientOrderCount | null | undefined // Pass the count as an argument
) => [
    {
        name: "Employees",
        href: "/admin/kpis/employees",
        icon: null,
        value: "check-in",
    },
    {
        name: `Clients`,
        href: "/admin/kpis/clients",
        icon: null,
        value: "received",
    },
    {
        name: `General`,
        href: "/admin/kpis/general",
        icon: null,
        value: "checked-in",
    },
];
