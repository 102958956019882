/** @format */

import { useEffect, useState } from "react";
import Header from "../../../components/header";
import { Invoice } from "../../../models/invoices";
import {
    createStripeBillingPortal,
    fetchInvoices,
    fetchPaymentDetails,
} from "./api/billingApi";
import Results from "../../../models/results";
import LoadingWheel from "../../../components/loadingWheel";
import { useNotification } from "../../../utils/notification/notificationContext";
import ClientSideBar from "../../../components/client/sideBar";
import { SecondaryButton } from "../../../components/buttons";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { exportData } from "../api/exportApi";
import InvoicesTable from "../../../tables/invoicesTable";
import { useSort } from "../../../hooks/useSort";
import { ArrowRight } from "lucide-react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export function PaymentDetailsAlertBanner({
    handleRedirect,
}: {
    handleRedirect: () => void;
}) {
    return (
        <div className="pb-4">
            <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                <div className="flex">
                    <div className="shrink-0">
                        <ExclamationTriangleIcon
                            aria-hidden="true"
                            className="size-5 text-yellow-400"
                        />
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                            You don't have a payment method saved.
                            <span
                                onClick={handleRedirect}
                                className="font-medium text-yellow-700 underline hover:text-yellow-600 ml-2 cursor-pointer"
                            >
                                Add a payment method before creating your first
                                inbound shipment.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BillingPage() {
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });
    const [totalSum, setTotalSum] = useState("");

    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const data = await fetchInvoices(sortConfig);
            if (data.status === "success") {
                setInvoices(data.data.invoices);
                setResults(data.data.results);
            } else {
                showNotification(
                    "Failed to fetch invoices.",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
        };
        fetchData();
        fetchPaymentData();
    }, [sortConfig]);

    const [exportLoading, setExportLoading] = useState(false);

    const [portalLoading, setPortalLoading] = useState(false);
    const handlePortalCreation = async () => {
        if (portalLoading) return;
        setPortalLoading(true);
        const data = await createStripeBillingPortal();
        if (data.status === "success") {
            window.location.href = data.data.url;
        } else {
            showNotification(
                "Failed to redirect to billing portal.",
                data.message,
                "error"
            );
        }
        setPortalLoading(false);
    };

    const [hasPaymentMethod, setHasPaymentMethod] = useState(true);
    const fetchPaymentData = async () => {
        const data = await fetchPaymentDetails();
        if (data.status === "success") {
            setHasPaymentMethod(data.data.has_payment_method);
        }
    };

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow">
                <div className="sm:px-10 px-4">
                    <Header />
                    <div className="pt-10 flex flex-col">
                        {!hasPaymentMethod && (
                            <PaymentDetailsAlertBanner
                                handleRedirect={() => handlePortalCreation()}
                            />
                        )}
                        <div className="flex flex-row items-center justify-between">
                            <span className="text-2xl font-bold">Billing</span>
                            <div
                                onClick={
                                    !portalLoading
                                        ? () => handlePortalCreation()
                                        : () => {}
                                }
                                className={`flex flex-row items-center space-x-2 text-sm text-gray-800 hover:text-accent cursor-pointer transition-all`}
                            >
                                <p>Manage payment details</p>
                                <ArrowRight className="h-4 w-4" />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row-reverse pt-4">
                        <SecondaryButton
                            label="Export"
                            iconDisplayed={ArrowUpTrayIcon}
                            handleClick={() =>
                                exportData(
                                    "billing",
                                    null,
                                    setExportLoading,
                                    showNotification
                                )
                            }
                            loading={exportLoading}
                            height={8}
                        />
                    </div>
                    {!loading ? (
                        <InvoicesTable
                            invoices={invoices}
                            results={results}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            setTotalSum={setTotalSum}
                        />
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BillingPage;
