/** @format */

import { useRef, useEffect, useState } from "react";
import { searchData } from "../../pages/admin/api/clientsApi";
import { useSearchParams } from "react-router-dom";

interface HeaderSearchBarProps {
    setData: any;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
    page: string;
    sub_page: string;
    user_id: string | null;
    keywords: string[];
    setKeywords: React.Dispatch<React.SetStateAction<string[]>>;
    inputValue: string;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
    refetchSearchedData: boolean;
}

export default function AdminSearchBar({
    setData,
    setLoading,
    handleRefetch,
    page,
    sub_page,
    user_id,
    keywords,
    setKeywords,
    inputValue,
    setInputValue,
    refetchSearchedData,
}: HeaderSearchBarProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            return;
        }

        const searchTerm =
            keywords.length > 0 ? keywords.join(";") : inputValue;

        searchData(
            searchTerm,
            user_id,
            page,
            sub_page,
            setData,
            setLoading,
            handleRefetch
        );

        return () => searchData.cancel();
    }, [keywords, inputValue, refetchSearchedData]); // Add inputValue to dependencies

    const [searchParams] = useSearchParams();
    useEffect(() => {
        const search = searchParams.get("search");
        if (search && search !== "") {
            setInputValue(search);
        }
    }, []);

    // Function to add a keyword when the user presses Enter
    const handleAddKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && inputValue.trim()) {
            setKeywords([...keywords, inputValue.trim()]);
            setInputValue("");
        }
    };

    // Function to remove a keyword
    const handleRemoveKeyword = (index: number) => {
        setKeywords(keywords.filter((_, i) => i !== index));
    };

    return (
        <div className="w-full">
            <input
                placeholder="Search"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="outline-none px-0 py-0 text-xs border border-gray-300 h-8 w-full rounded-md pl-2 focus:border-gray-400 focus:ring-0"
                ref={inputRef}
                onKeyDown={handleAddKeyword}
            />
            <div className="flex flex-wrap mt-2">
                {keywords.map((keyword, index) => (
                    <div
                        key={index}
                        className="bg-gray-100 px-2 py-1 rounded-md flex items-center space-x-2 m-1"
                    >
                        <span className="text-sm">{keyword}</span>
                        <button
                            onClick={() => handleRemoveKeyword(index)}
                            className="text-red-500 text-xs"
                        >
                            x
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}
