/** @format */

import { useEffect, useRef, useState } from "react";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import { fetchInventoryOrders } from "./../api/inventoryApi";
import Results from "../../../../models/results";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { Order } from "../../../../models/order";
import { useSearchParams } from "react-router-dom";
import { useSort } from "../../../../hooks/useSort";
import ClientSearchBar from "../../../../components/client/searchBar";
import { BulkEditInventoryOrdersModal } from "../inventory-data/bulkEditInventoryOrders";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import LoadingWheel from "../../../../components/loadingWheel";
import { Column } from "../../../../tables/models";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import { orderTypes } from "../../../../models/data";
import { useClient } from "../../../../utils/client/clientContext";
import ConfirmRequestShipmentsModal from "./confirmRequestShipmentsModal";
import { ActionButton, SecondaryButton } from "../../../../components/buttons";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { exportData } from "../../api/exportApi";
import ClientLabelsModal from "./clientLabelsModal";
import { SiAmazonapigateway } from "react-icons/si";
import { MdOutlineOutbound } from "react-icons/md";
import RequestShippingDetailsModal from "./requestShippingDetailsModal";
import { SmallSelectorWithNavigate } from "../../../../components/dropdowns/smallSelector";
import RequestActionsModal from "./requestActionsModal";

function InventoryUnitsPage() {
    const { showNotification } = useNotification();
    const { orderCount, handleOrderCountRefetch } = useClient();

    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(true);

    const [orders, setOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [shipmentFrequency, setShipmentFrequency] = useState("");

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = (fromSearch: boolean = false) => {
        setRefetchData((prevValue) => prevValue + 1);
        if (!fromSearch) {
            handleOrderCountRefetch();
        }
    };

    const hasFetchedOrders = useRef(false);
    const fetchData = async () => {
        const data = await fetchInventoryOrders(searchParams, sortConfig);
        if (data.status === "success") {
            setOrders(data.data.inventory);
            setResults(data.data.results);
            setShipmentFrequency(data.data.fba_shipment_frequency);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
        setSelectedOrders([]);
        hasFetchedOrders.current = true;
    };

    const [firstLoad, setFirstLoad] = useState(false);

    useEffect(() => {
        if (firstLoad) {
            setLoading(true);
            fetchData();
        } else {
            setFirstLoad(true);
        }
    }, [refetchData]);

    useEffect(() => {
        if (hasFetchedOrders.current) {
            setSortLoading(true);
            fetchData();
        }
    }, [sortConfig]);

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [
        openRequestOutboundShipmentModal,
        setOpenRequestOutboundShipmentModal,
    ] = useState(false);

    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    const [totalSum, setTotalSum] = useState("");

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);
    const hiddenHeaders = [
        "input_shipping_quantity",
        "remove_selected_row",
        "input_shelf_location",
        "location",
    ];

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );

    const [
        openConfirmRequestShipmentsModal,
        setOpenConfirmRequestShipmentsModal,
    ] = useState(false);

    const [exportLoading, setExportLoading] = useState(false);

    const [openClientLabelsModal, setOpenClientLabelsModal] = useState(false);

    const [
        openRequestShippingDetailsModal,
        setOpenRequestShippingDetailsModal,
    ] = useState(false);

    const [openRequestActionsModal, setOpenRequestActionsModal] =
        useState(false);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto min-h-screen">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                        <div className="flex flex-row items-center">
                            <span className="text-2xl font-bold">
                                Inventory
                            </span>
                            <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                                {orderCount?.inventory}
                            </span>
                        </div>
                        <div className="sm:space-x-4 flex flex-col sm:flex-row sm:items-center sm:space-y-0 space-y-2 sm:pt-0 pt-2">
                            <SecondaryButton
                                label="My Labels"
                                handleClick={() =>
                                    setOpenClientLabelsModal(true)
                                }
                                height={8}
                            />
                            <ActionButton
                                label="Request Actions"
                                handleClick={() =>
                                    setOpenRequestActionsModal(true)
                                }
                                viewDisabled={selectedOrders.length < 1}
                                height={8}
                            />
                            {/* {shipmentFrequency === "on_client_request" && (
                                <ActionButton
                                    label="Request FBA Shipments"
                                    handleClick={() =>
                                        setOpenConfirmRequestShipmentsModal(
                                            true
                                        )
                                    }
                                    viewDisabled={
                                        !selectedOrders.some(
                                            (order) =>
                                                order.fulfilled_by === "amazon"
                                        )
                                    }
                                    height={8}
                                    iconDisplayed={SiAmazonapigateway}
                                />
                            )} */}
                        </div>
                    </div>

                    <div className="flex flex-row items-start py-4 space-x-4">
                        <SmallSelectorWithNavigate
                            selectorData={[
                                {
                                    id: 2,
                                    name: "Units",
                                    value: "/inventory/units",
                                },
                            ]}
                        />
                        <ClientSearchBar
                            setData={setOrders}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="inventory"
                            sub_page="units"
                        />
                        <ColumnOrderDropdown
                            columnOrder={unitsColumnOrder}
                            setColumnOrder={setUnitsColumnOrder}
                            localStorageKey="unitsColumnOrder"
                            selectedOrderType={selectedOrderType}
                            initialColumnOrder={getTableHeaders(
                                selectedOrderType.value
                            )}
                            buttonHeightClass="h-8"
                        />
                        <SecondaryButton
                            label="Export"
                            iconDisplayed={ArrowUpTrayIcon}
                            handleClick={() =>
                                exportData(
                                    "inventory",
                                    "units",
                                    setExportLoading,
                                    showNotification
                                )
                            }
                            loading={exportLoading}
                            height={8}
                        />
                        <button
                            onClick={() => setOpenBulkEditModal(true)}
                            disabled={selectedOrders.length < 1}
                            className={`flex h-8 flex-row items-center text-xs px-4 shadow-sm rounded-md text-black ${
                                selectedOrders.length > 0
                                    ? "hover:bg-gray-50 bg-white border border-gray-400"
                                    : "bg-gray-50 border border-gray-200"
                            }`}
                        >
                            Edit
                        </button>
                    </div>

                    {!loading ? (
                        <OrdersTable
                            orderStatus="inventory"
                            orders={orders}
                            results={results}
                            selectedOrders={selectedOrders}
                            setSelectedOrders={setSelectedOrders}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            client_type="client"
                            setTotalSum={setTotalSum}
                            columnOrder={unitsColumnOrder}
                            hiddenHeaders={hiddenHeaders}
                        />
                    ) : (
                        <div className="flex flex-grow items-center pb-44 justify-center">
                            {loading ? (
                                <LoadingWheel />
                            ) : (
                                <span className="text-sm text-gray-500">
                                    User has no inbound orders
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <BulkEditInventoryOrdersModal
                orderIds={selectedOrders.map((order) => order.order_id)}
                openEditModal={openBulkEditModal}
                setOpenEditModal={setOpenBulkEditModal}
                handleRefetch={handleRefetch}
            />

            <ClientLabelsModal
                openClientLabelsModal={openClientLabelsModal}
                setOpenClientLabelsModal={setOpenClientLabelsModal}
            />

            <RequestActionsModal
                openRequestActionsModal={openRequestActionsModal}
                setOpenRequestActionsModal={setOpenRequestActionsModal}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default InventoryUnitsPage;
