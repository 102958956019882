/** @format */

export default function LoadingWheel({ size = "", color = "black" }) {
    if (size === "xs") {
        return (
            <div
                className={`inline-block h-3 w-3 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] ${
                    color === "white" ? "text-white" : "text-black"
                }`}
                role="status"
            >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                </span>
            </div>
        );
    }
    if (size === "small") {
        return (
            <div
                className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] ${
                    color === "white" ? "text-white" : "text-black"
                }`}
                role="status"
            >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                </span>
            </div>
        );
    }
    return (
        <div
            className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] ${
                color === "white" ? "text-white" : "text-black"
            }`}
            role="status"
        >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
            </span>
        </div>
    );
}

export function SortLoading({
    sortLoading,
    scrollOffset,
    removePadding = false,
}: {
    sortLoading: boolean;
    scrollOffset: number;
    removePadding?: boolean;
}) {
    return (
        <>
            {sortLoading && (
                <div
                    className="absolute inset-0 z-50 h-full"
                    style={{
                        transform: `translateX(${scrollOffset}px)`, // Apply the scroll translation
                    }}
                >
                    <div className="w-full h-full flex items-center justify-center">
                        <div
                            className={`${
                                removePadding ? "" : "mt-9"
                            } z-50 absolute inset-0 bg-gray-200 bg-opacity-50`}
                        ></div>
                        <div className="z-50 mt-9">
                            <LoadingWheel />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
