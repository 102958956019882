import { apiRequest } from "../../../../utils/api/apiRequest"


export const fetchEmployeesKpis = async () => {
    return apiRequest("/admin/kpis", "GET")
}

export const fetchClientsKpis = async () => {
    return apiRequest("/admin/kpis/clients", "GET")
}

export const fetchGeneralKpis = async () => {
    return apiRequest("/admin/kpis/general")
}