/** @format */

import { PaymentStatusTag } from "../../../../components/statusTags";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import {
    Invoice,
    ItemBilled,
    ShipmentBilled,
} from "../../../../models/invoices";
import {
    formatInvoiceMoney,
    formatMoney,
} from "../../../../utils/price/formatMoney";
import { formatDateFromDb } from "../../../../utils/date/date_formatter";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import OutboundOrdersModal from "../../outbound/outboundOrdersModal";
import { useEffect, useState } from "react";
import { fetchStripeInvoice } from "../api/billingApi";

export default function InvoiceDetails({
    shipmentsBilled,
    itemsBilled,
    total,
    invoiceData,
}: {
    shipmentsBilled: ShipmentBilled[];
    itemsBilled: ItemBilled[];
    total: number | null;
    invoiceData: Invoice | null;
}) {
    const { invoice_id } = useParams();
    const { showNotification } = useNotification();

    function getDate() {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const date = String(today.getDate()).padStart(2, "0");
        const year = today.getFullYear();
        return `${month}/${date}/${year}`;
    }

    const [openOutboundOrders, setOpenOutboundOrders] = useState(false);
    const [selectedShipmentId, setSelectedShipmentId] = useState<string | null>(
        null
    );

    useEffect(() => {
        if (selectedShipmentId) {
            setOpenOutboundOrders(true);
        }
    }, [selectedShipmentId]);

    useEffect(() => {
        if (!openOutboundOrders) {
            setSelectedShipmentId(null);
        }
    }, [openOutboundOrders]);

    const handleInvoiceView = async () => {
        const data = await fetchStripeInvoice(
            invoiceData?.stripe_invoice_id || ""
        );
        if (data.status === "success") {
            window.open(data.data.invoice_url, "_blank");
        }
    };

    return (
        <div className="py-8 sm:rounded-lg sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:pb-20 xl:pt-16">
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center space-x-4 text-sm">
                    <span className="text-2xl font-bold">Invoice</span>
                    <PaymentStatusTag
                        status={invoiceData?.payment_status || ""}
                    />
                </div>
                <div className="flex flex-row items-center space-x-4 pt-4 sm:pt-0">
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(invoice_id || "");
                            showNotification(
                                "Copied invoice ID",
                                "",
                                "success"
                            );
                        }}
                        className="flex flex-row items-center border border-gray-300 hover:border-gray-300 rounded-md px-2 h-8 hover:bg-gray-50"
                    >
                        <ClipboardIcon className="h-4 w-4" />
                        <span className="pl-2 text-sm">Copy ID</span>
                    </button>
                    {invoiceData?.invoice_url ? (
                        <button
                            onClick={() => handleInvoiceView()}
                            className="bg-accent text-white font-medium px-3 text-sm rounded-md h-8 hover:bg-accenthighlight"
                        >
                            View payment
                        </button>
                    ) : (
                        <button className="bg-accentdim text-white font-medium px-3 text-sm rounded-md h-8 cursor-default">
                            Waiting on URL
                        </button>
                    )}
                </div>
            </div>
            <div className="sm:pr-4 text-sm pt-6">
                {invoiceData?.invoice_date ? (
                    <>
                        <dt className="inline text-gray-500">Issued on</dt>{" "}
                        <dd className="inline text-gray-700">
                            {formatDateFromDb(invoiceData?.invoice_date)}
                        </dd>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="mt-2 sm:mt-0 text-sm pt-4">
                <dt className="inline text-gray-500">Invoice for</dt>{" "}
                <dd className="inline text-gray-700">
                    {formatDateFromDb(invoiceData?.start_date || "")}
                    {" - "}{" "}
                    {invoiceData?.end_date
                        ? formatDateFromDb(invoiceData?.end_date)
                        : getDate()}
                </dd>
            </div>

            <table className="mt-12 w-full whitespace-nowrap text-left text-sm leading-6">
                <colgroup>
                    <col className="w-full" />
                    <col />
                    <col />
                    <col />
                </colgroup>
                <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                        <th scope="col" className="px-0 py-3 font-semibold">
                            Shipments billed
                        </th>
                        <th
                            scope="col"
                            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                        >
                            Total units
                        </th>
                        <th
                            scope="col"
                            className="py-3 pl-8 pr-0 text-right font-semibold"
                        >
                            View
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {shipmentsBilled.map((item) => (
                        <tr
                            key={item.name}
                            className="border-b border-gray-100"
                        >
                            <td className="max-w-0 px-0 py-5 align-top">
                                <div className="truncate font-medium text-gray-900">
                                    {item.name}
                                </div>
                                <div className="truncate text-gray-500">
                                    {item.order_count} outbound orders in this
                                    shipment
                                </div>
                            </td>
                            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                {item.total_quantity}
                            </td>
                            <td
                                onClick={() =>
                                    setSelectedShipmentId(item.shipment_id)
                                }
                                className="py-5 pl-8 pr-0 text-right align-top text-accent tabular-nums cursor-pointer"
                            >
                                View
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className="mt-12 w-full whitespace-nowrap text-left text-sm leading-6">
                <colgroup>
                    <col className="w-full" />
                    <col />
                    <col />
                    <col />
                </colgroup>
                <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                        <th scope="col" className="px-0 py-3 font-semibold">
                            Items
                        </th>
                        <th
                            scope="col"
                            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                        >
                            Amount
                        </th>
                        <th
                            scope="col"
                            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                        >
                            Unit price
                        </th>
                        <th
                            scope="col"
                            className="py-3 pl-8 pr-0 text-right font-semibold"
                        >
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {itemsBilled.map((item) => (
                        <tr
                            key={item.title}
                            className="border-b border-gray-100"
                        >
                            <td className="max-w-0 px-0 py-5 align-top">
                                <div className="truncate font-medium text-gray-900">
                                    {item.title}
                                </div>
                                <div className="truncate text-gray-500">
                                    {item.description}
                                </div>
                            </td>
                            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                {item.amount}
                            </td>
                            <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                {formatInvoiceMoney(item.unit_price)}
                            </td>
                            <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                                {formatInvoiceMoney(item.total_price)}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th
                            scope="row"
                            className="pt-4 font-semibold text-gray-900 sm:hidden"
                        >
                            Total
                        </th>
                        <th
                            scope="row"
                            colSpan={3}
                            className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                        >
                            Total
                        </th>
                        <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                            {formatMoney(total || 0)}
                        </td>
                    </tr>
                </tfoot>
            </table>
            <OutboundOrdersModal
                shipmentLoaded={null}
                openOutboundOrders={openOutboundOrders}
                setOpenOutboundOrders={setOpenOutboundOrders}
                shipment_id={selectedShipmentId}
            />
        </div>
    );
}
